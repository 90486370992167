import React from 'react';

import { App, Modal, Spin, Table, TableColumnsType, Tag, Typography } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';

import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { translateAction } from 'lib/helpers/Trigger.helper';
import { useWhatsApp } from 'lib/providers/WhatsAppContextProvider';
import { cancelExecutionTrigger } from 'services/Trigger.service';

export function ListTriggerLogModal() {
    const [isCanceling, setIsCanceling] = React.useState(false);
    const { trigger, isTriggersLoading, setIsTriggerLogModalVisible, fetchTriggers } = useWhatsApp();

    const app = App.useApp();

    const handleCancelExecution = async (log_id: number) => {
        setIsCanceling(true);

        const response = await cancelExecutionTrigger(log_id);

        fetchTriggers();
        setIsCanceling(false);

        if (!response.success)
            return app.notification.error({ message: 'Erro ao cancelar execução', description: response.message });

        app.notification.success({ message: 'Execução cancelada com sucesso' });
    };

    const COLUMNS: TableColumnsType<Trigger.Log> = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Ação',
            dataIndex: 'action',
            render: (action: Trigger.ActionType) => translateAction(action),
        },
        {
            title: 'Destinatário',
            dataIndex: ['action_to', 'action_to_name'],
            render: (_, log) => `${log.action_to_name} (${log.action_to})`,
        },
        {
            title: 'Mensagem',
            dataIndex: 'message',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: Trigger.Status) => {
                switch (status) {
                case 'pending':
                    return <Tag color='warning'>Pendente</Tag>;
                case 'executed':
                    return <Tag color='success'>Executado</Tag>;
                case 'failed':
                    return <Tag color='error'>Falhou</Tag>;
                case 'canceled':
                    return <Tag color='default'>Cancelado</Tag>;
                default:
                    return status;
                };
            }
        },
        {
            title: 'Adicionado na fila',
            dataIndex: 'created_at',
            width: 150,
            render: (date: string) => dayjs(date).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: 'Executado em',
            dataIndex: 'executed_at',
            width: 215,
            render: (date: string, log) =>
                (date && log.status === 'executed')
                    ? dayjs(date).format('DD/MM/YYYY HH:mm')
                    : log.status === 'canceled'
                        ? '-'
                        : <Popconfirm
                            title="Deseja realmente cancelar a execução?"
                            onConfirm={() => handleCancelExecution(log.id)}
                            okText="Sim"
                            cancelText="Não"
                            disabled={isCanceling}>
                            <Typography.Text style={{ cursor: 'pointer' }} type='secondary'>Aguardando execução (clique aqui para cancelar) <Spin indicator={<LoadingOutlined style={{ color: 'gray' }} spin />} size="small" /></Typography.Text>
                        </Popconfirm>,
        }
    ];

    return (
        <Modal
            onCancel={() => setIsTriggerLogModalVisible(false)}
            title={`${trigger?.name} - Disparos programados e realizados`}
            style={{ top: 10 }}
            destroyOnClose
            width={'70%'}
            footer={null}
            open>
            <Table
                bordered
                size='small'
                columns={COLUMNS}
                tableLayout='auto'
                dataSource={trigger?.logs}
                loading={isTriggersLoading}
            />
        </Modal>
    );
};