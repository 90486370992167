import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listConstructions } from 'services/Construction.service';
import { listIssue } from 'services/Issue.service';
import { listMaintenance } from 'services/Maintenance.service';
import { deleteServiceBudget, listServiceBudgets } from 'services/ServiceBudget.service';

type Value = {
    isLoading: boolean,
    issues: Issue.Model[],
    maintenances: Maintenance.Model[],
    constructions: Construction.Model[],
    serviceBudgets: ServiceBudget.Model[],
    setServiceBudgets: Dispatch<SetStateAction<Value['serviceBudgets']>>,
    serviceBudget: ServiceBudget.Model | null,
    serviceBudgetId: ServiceBudget.Model['id'] | null,
    setServiceBudgetId: Dispatch<SetStateAction<Value['serviceBudgetId']>>,
    isCreateModalVisible: boolean,
    setIsChangeStatusModalVisible: Dispatch<SetStateAction<Value['isChangeStatusModalVisible']>>,
    isChangeStatusModalVisible: boolean,
    setIsHistoryModalVisible: Dispatch<SetStateAction<Value['isHistoryModalVisible']>>,
    isHistoryModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    isDetailModalVisible: boolean,
    setIsDetailModalVisible: Dispatch<SetStateAction<Value['isDetailModalVisible']>>,
    isRequestModalVisible: boolean,
    setIsRequestModalVisible: Dispatch<SetStateAction<Value['isRequestModalVisible']>>,
    fetchServiceBudgets: () => Promise<void>,
    removeServiceBudget: (id: ServiceBudget.Model['id']) => any
    isEditServiceBudgetOptionVisible: boolean,
    setIsEditServiceBudgetOptionVisible: Dispatch<SetStateAction<Value['isEditServiceBudgetOptionVisible']>>,
    serviceBudgetOption: ServiceBudget.Request | undefined,
    setServiceBudgetOption: Dispatch<SetStateAction<Value['serviceBudgetOption']>>,
    clientId: Client.Model['id'] | undefined,
    setClientId: Dispatch<SetStateAction<Value['clientId']>>,
};

type Props = { children: (value: Value) => ReactNode };

const ServiceBudgetContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function ServiceBudgetContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [issues, setIssues] = useState<Issue.Model[]>([]);
    const [maintenances, setMaintenances] = useState<Maintenance.Model[]>([]);
    const [constructions, setConstructions] = useState<Construction.Model[]>([]);
    const [serviceBudgets, setServiceBudgets] = useState<ServiceBudget.Model[]>([]);
    const [serviceBudgetId, setServiceBudgetId] = useState<ServiceBudget.Model['id'] | null>(null);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState(false);
    const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
    const [isRequestModalVisible, setIsRequestModalVisible] = useState(false);
    const [isEditServiceBudgetOptionVisible, setIsEditServiceBudgetOptionVisible] = useState(false);
    const [serviceBudgetOption, setServiceBudgetOption] = useState<ServiceBudget.Request | undefined>(undefined);
    const [clientId, setClientId] = useState<number | undefined>(undefined);

    const app = App.useApp();

    const fetchIssuesAndMaintenanceAndConstruction = async () => {
        if (!clientId) {
            return Promise.all([]);
        }

        const promises = [];

        promises.push(listIssue([clientId]).then((response) => {
            if (!response.success)
                return;

            setIssues(response.issues.filter(i => i.status === 'opened'));
        }));

        promises.push(listMaintenance([clientId]).then((response) => {
            if (!response.success)
                return;

            setMaintenances(response.maintenances.filter(m => !m.isFinished));
        }));

        promises.push(listConstructions(clientId).then((response) => {
            if (!response.success)
                return;

            setConstructions(response.constructions);
        }));

        Promise.all(promises);
    };

    const fetchServiceBudgets = async () => {
        setIsLoading(true);

        const response = await listServiceBudgets();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setServiceBudgets(response.service_budgets);
    };

    const removeServiceBudget = async (id: ServiceBudget.Model['id']) => {
        const response = await deleteServiceBudget(id);

        if (!response.success)
            return handleServiceError(app, response);

        return true;
    };

    const memoizedFetchServiceBudgets = useCallback(fetchServiceBudgets, [app]);

    useEffect(() => {
        fetchIssuesAndMaintenanceAndConstruction();
        memoizedFetchServiceBudgets();
    }, [clientId, memoizedFetchServiceBudgets]);

    const serviceBudget = useMemo(() => {
        if (!serviceBudgetId)
            return null;

        const found = serviceBudgets.find(serviceBudget => serviceBudget.id === serviceBudgetId);

        if (!found)
            throw new Error(`Could not find a serviceBudget with id ${serviceBudgetId}`);

        return found;

    }, [serviceBudgets, serviceBudgetId]);

    const value: Value = {
        isLoading,
        issues,
        maintenances,
        constructions,
        serviceBudgets,
        setServiceBudgets,
        serviceBudget,
        serviceBudgetId,
        setServiceBudgetId,
        isCreateModalVisible,
        setIsChangeStatusModalVisible,
        isChangeStatusModalVisible,
        setIsHistoryModalVisible,
        isHistoryModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        isDetailModalVisible,
        setIsDetailModalVisible,
        isRequestModalVisible,
        setIsRequestModalVisible,
        fetchServiceBudgets: memoizedFetchServiceBudgets,
        removeServiceBudget,
        setIsEditServiceBudgetOptionVisible,
        isEditServiceBudgetOptionVisible,
        setServiceBudgetOption,
        serviceBudgetOption,
        setClientId,
        clientId,
    };

    return (
        <ServiceBudgetContext.Provider value={value}>
            {children(value)}
        </ServiceBudgetContext.Provider>
    );
}

export function useServiceBudget() {
    const context = useContext(ServiceBudgetContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `ServiceBudgetContextProvider`.');

    return context;
}