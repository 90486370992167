import { createElement } from 'react';

import { TagProps } from 'antd';

import { CheckCircleFilled, CloseCircleOutlined, HourglassOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

type VacationTimeStatusTagProps = Pick<TagProps, 'color' | 'icon' | 'children'>;

export function getVacationTimeStatus(vacationTimeAcquired: Employee.VacationTimeAcquired.Model): VacationTimeStatusTagProps {
    const { status } = vacationTimeAcquired;

    let vacationStatusTag: VacationTimeStatusTagProps = {
        color: 'orange',
        icon: createElement(HourglassOutlined),
        children: 'Férias pendentes',
    };

    switch (status) {
    case 'pending':
        vacationStatusTag = {
            color: 'orange',
            icon: createElement(HourglassOutlined),
            children: 'Férias pendentes',
        };
        break;
    case 'finished':
        vacationStatusTag = {
            color: 'green',
            icon: createElement(CheckCircleFilled),
            children: 'Férias Concluídas',
        };
        break;
    case 'scheduled':
        vacationStatusTag = {
            color: 'blue',
            icon: createElement(CheckCircleFilled),
            children: 'Férias Agendadas',
        };
        break;
    case 'overdue':
        vacationStatusTag = {
            color: 'blue',
            icon: createElement(CheckCircleFilled),
            children: 'Férias Agendadas',
        };
        break;
    }

    return vacationStatusTag;
}

export function getEmployeeStatus(employee: Employee.Model, vacationTimes: Employee.VacationTime.Model): VacationTimeStatusTagProps {

    let employeeStatusTag: VacationTimeStatusTagProps = {
        color: 'geekblue',
        icon: createElement(HourglassOutlined),
        children: employee.status === 'in_vacation' ? 'Em férias' : 'Trabalhando',
    };

    if (employee.status === 'in_vacation') {
        employeeStatusTag = {
            color: 'gold',
            icon: createElement(CloseCircleOutlined),
            children: 'Em férias',
        };
    } else if (employee.status === 'working') {
        employeeStatusTag = {
            color: 'geekblue',
            icon: createElement(HourglassOutlined),
            children: 'Trabalhando',
        };
    }

    return employeeStatusTag;
}
