import React from 'react';

import { type TableColumnsType, Tag, TagProps } from 'antd';

import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { DocumentActionsCell } from 'components/Document/DocumentActionsCell';
import dayjs from 'dayjs';

export const Columns: TableColumnsType<Document.With<'client' | 'document_type' | 'files'>> = [
    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
        key: 'clientName'
    },

    {
        title: 'Título',
        dataIndex: 'title',
    },

    {
        title: 'Tipo',
        dataIndex: ['document_type', 'name'],
    },

    {
        title: 'Vigência',
        render: (_, record) => {
            if (!record.start_date && !record.end_date) {
                return 'N/A';
            }

            const days = dayjs(record.end_date).diff(dayjs(), 'days');

            if (days < 0)
                return `Vencido há ${days} dia(s)`;

            return `Vence em ${days} dia(s)`;
        }
    },

    {
        title: 'Situação',
        dataIndex: 'situation',
        render: value => {
            const props: TagProps = value === 'valid'
                ? { icon: <CheckCircleOutlined />, color: 'success', children: 'Válido' }
                : value === 'invalid'
                    ? { icon: <ExclamationCircleOutlined />, color: 'orange', children: 'Inválido' }
                    : { icon: <ClockCircleOutlined />, color: 'red', children: 'Vencido' };

            return <Tag {...props} />;
        },
        width: 140,

    },

    {
        title: 'Arquivos',
        dataIndex: ['files', 'length'],
        key: 'files',
        width: 60,
    },

    {
        width: 120,
        align: 'end',
        key: 'actions',
        render: (_, document) => <DocumentActionsCell document={document} />,
    },
];