import { useAppProps } from 'antd/es/app/context';

import API from 'lib/API';

export type Options = {
    message?: (message: Service.Message) => Service.Message | Service.Message,
};

/** @see https://ant.design/components/app */
export function handleServiceError({ notification }: useAppProps, response: Service.ExceptionResponse, options?: Options) {
    // We already show an alert to the user (Request.ts#request#asyncCall)
    if (Array.isArray(response) && response.length === 0)
        return;

    if (!response.message)
        return notification.error({ message: 'Atenção', description: JSON.stringify(response) });

    if (options !== undefined && typeof options.message === 'string')
        return notification.error({ message: 'Atenção', description: options.message });

    if (options !== undefined && typeof options.message === 'function')
        return notification.error({ message: 'Atenção', description: options.message(response.message) });

    notification.error({ message: 'Atenção', description: response.message });
}

/** @factory */
export function makeUploadAction(): string {
    const action = `${API.api_url}/file/upload`;

    return action;
}