export const translateModule = (module: string): string => {
    switch (module) {
    case 'issue':
        return 'Chamado';
    case 'maintenance':
        return 'Manutenção';
    case 'construction':
        return 'Obra';
    default:
        return module;
    };
};

export const translateEvent = (event: string): string => {
    switch (event) {
    case 'create':
        return 'Criado(a)';
    case 'update':
        return 'Atualizado(a)';
    case 'finish':
        return 'Finalizado(a)';
    case 'reopen':
        return 'Reaberto(a)';
    case 'assign':
        return 'Atribuído(a)';
    default:
        return event;
    };
};

export const translateAction = (action: string): string => {
    switch (action) {
    case 'whatsapp_group_id':
        return 'Grupo de condôminos';
    case 'whatsapp_workgroup_id':
        return 'Grupo de trabalho do condomínio';
    case 'client_manager_whatsapp_group_id':
        return 'Grupo da empresa';
    case 'user_responsible_id':
        return 'Responsável';
    case 'user_manager_id':
        return 'Síndico';
    case 'supplier_id':
        return 'Fornecedor';
    default:
        return action;
    };
};

export const translateCondition = (condition: string): string => {
    switch (condition) {
    case 'clientId':
        return 'Condomínio';
    case 'client_id':
        return 'Condomínio';
    case 'issueTypeId':
        return 'Categoria';
    case 'user_responsible_id':
        return 'Responsável';
    case 'supplier_id':
        return 'Fornecedor';
    case 'status':
        return 'Status';
    case 'maintenance_type_id':
        return 'Categoria';
    default:
        return condition;
    };
};

export const translateStatus = (status: string): string => {
    switch (status) {
    case 'pending':
        return 'Pendente';
    case 'executed':
        return 'Executado';
    case 'failed':
        return 'Falhou';
    default:
        return status;
    };
};

export const actions: { label: string, value: string }[] = [
    {
        label: 'Grupo de condôminos',
        value: 'whatsapp_group_id'
    },
    {
        label: 'Grupo de trabalho do condomínio',
        value: 'whatsapp_workgroup_id'
    },
    {
        label: 'Grupo da empresa',
        value: 'client_manager_whatsapp_group_id'
    },
    {
        label: 'Responsável',
        value: 'user_responsible_id'
    },
    {
        label: 'Síndico',
        value: 'user_manager_id'
    },
    {
        label: 'Fornecedor',
        value: 'supplier_id'
    }
];


export const issueStatus = [
    {
        label: 'Pendente',
        value: 'opened'
    },
    {
        label: 'Finalizado',
        value: 'closed'
    },
    {
        label: 'Em andamento',
        value: 'in_progress'
    },
    {
        label: 'Aguardando assembleia',
        value: 'waiting_ogm'
    },
    {
        label: 'Atrasado',
        value: 'overdue'
    }
];

export const maintenanceStatus = [
    {
        label: 'Pendente',
        value: 'false'
    },
    {
        label: 'Finalizada',
        value: 'true'
    }
];

export const constructionStatus = [
    {
        label: 'Planejamento',
        value: 'planning'
    },
    {
        label: 'Em andamento',
        value: 'in_progress'
    },
    {
        label: 'Paralisada',
        value: 'paralyzed'
    },
    {
        label: 'Atrasada',
        value: 'late'
    },
    {
        label: 'Finalizada',
        value: 'finished'
    }
];