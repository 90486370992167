import React, { useEffect, useState } from 'react';

import { App, DatePicker, Form, Modal, notification, UploadFile } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import dayjs from 'dayjs';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useConstruction } from 'lib/providers/ConstructionContextProvider';
import { UploadField } from 'lib/UploadField';
import { createConstructionFollowUpFile, updateConstructionFollowUp } from 'services/Construction.service';
import { CreateConstructionFollowUpFile } from 'services/contracts/Construction.contract';

export type Values = {
    description: Construction.FollowUp.Model['description'],
    creation_date: dayjs.Dayjs,
    files: UploadFile[] | undefined,
};

export default function EditFollowUpModal() {
    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);

    const {
        construction,
        followUp,
        followUpId,
        setFollowUpId,
        setIsEditFollowUpModal,
        fetchConstructions
    } = useConstruction();

    const app = App.useApp();

    useEffect(() => {
        if (followUp) {

            const files: UploadFile[] = followUp.files?.map(file => (
                {
                    uid: file.url,
                    name: file.filename,
                    url: file.url,
                    status: 'done',
                }
            ));

            const {
                description,
                creation_date,
            } = followUp;

            form.setFieldsValue({
                description,
                creation_date: creation_date ? dayjs(creation_date) : undefined,
                files: files
            });
        }
    }, [followUp, form]);

    if (!followUp) {
        throw new Error('O acompanhamento não está definido');
    }


    if (!construction)
        throw new Error('Value of the `construction` property is unknown');

    const close = () => {
        setIsEditFollowUpModal(false);
        setSaving(false);
        setFollowUpId(null);
    };

    async function onFinish() {
        try {

            const values = await form.validateFields();

            const { creation_date, ...restValues } = values;

            if (values.files && values.files.some(file => file.status === 'uploading')) {
                return app.notification.error(
                    {
                        message: 'Atenção',
                        description: 'Aguarde o término do upload dos arquivos!'
                    }
                );
            }

            setSaving(true);

            const formattedDate = dayjs(creation_date).format('YYYY-MM-DD');

            if (followUpId === null) {
                throw new Error('ID do acompanhamento não encontrado.');
            }

            const updateResponse = await updateConstructionFollowUp(followUpId, {
                creation_date: formattedDate,
                ...restValues,
            });

            if (!updateResponse.success) {
                setSaving(false);
                return handleServiceError(app, updateResponse);
            }

            const parsedFiles: CreateConstructionFollowUpFile.File[] = values.files?.map((file: UploadFile): CreateConstructionFollowUpFile.File => ({
                filename: file.name,
                url: file.response ?? file.url,
                construction_follow_up_id: followUpId,
            })) ?? [];

            const filesResponse = await createConstructionFollowUpFile(parsedFiles);

            if ('success' in filesResponse && !filesResponse.success) {
                setSaving(false);
                return handleServiceError(app, filesResponse);
            }

            notification.success({
                message: 'Sucesso',
                description: 'Acompanhamento editado com sucesso',
            });

            await fetchConstructions();
            setSaving(false);
            close();
        } catch (error) {
            console.error(error);
            setSaving(false);
        }
    }




    return (
        <Modal
            centered
            title="Editar acompanhamento"
            destroyOnClose={true}
            confirmLoading={isSaving}
            onOk={onFinish}
            onCancel={close}
            afterClose={() => form.resetFields()}
            okText="Salvar"
            open
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="description"
                    label="Descreva"
                    rules={[{ required: true, message: 'Descreva' }]}>
                    <TextArea rows={5} allowClear />
                </Form.Item>

                <Form.Item<Values>
                    name="creation_date"
                    label="Data do acompanhamento"
                    initialValue={dayjs()}
                    rules={[{ required: true, message: 'Selecione uma data' }]}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                    />
                </Form.Item>

                <UploadField
                    name="files"
                    type="picture"
                    multiple
                />
            </Form>
        </Modal >
    );

};