import API from '../lib/API';
import { CancelExecutionTrigger, CreateTrigger, CreateTriggerAction, CreateTriggerCondition, DeleteTrigger, DeleteTriggerAction, DeleteTriggerCondition, DisableTrigger, EnableTrigger, ListTriggers, RestoreTrigger, UpdateTrigger } from './contracts/Trigger.contract';

const route = '/trigger';

export function listTriggers(): Promise<ListTriggers.Response> {
    return API.get(route);
};

export function createTrigger(body: CreateTrigger.Body): Promise<CreateTrigger.Response> {
    return API.post(route, body);
};

export function updateTrigger(id: Trigger.Model['id'], body: CreateTrigger.Body): Promise<UpdateTrigger.Response> {
    return API.put(`${route}/${id}`, body);
}

export function deleteTrigger(id: Trigger.Model['id']): Promise<DeleteTrigger.Response> {
    return API.delete(`${route}/${id}`);
};

export function restoreTrigger(id: Trigger.Model['id']): Promise<RestoreTrigger.Response> {
    return API.put(`${route}/restore/${id}`);
};

export function enableTrigger(id: Trigger.Model['id']): Promise<EnableTrigger.Response> {
    return API.put(`${route}/enable/${id}`);
};

export function disableTrigger(id: Trigger.Model['id']): Promise<DisableTrigger.Response> {
    return API.put(`${route}/disable/${id}`);
};

export function createTriggerCondition(body: CreateTriggerCondition.Body): Promise<CreateTriggerCondition.Response> {
    return API.post(`${route}/condition`, body);
};

export function deleteTriggerCondition(id: Trigger.Condition['id']): Promise<DeleteTriggerCondition.Response> {
    return API.delete(`${route}/condition/${id}`);
};

export function createTriggerAction(body: CreateTriggerAction.Body): Promise<CreateTriggerAction.Response> {
    return API.post(`${route}/action`, body);
};

export function deleteTriggerAction(id: Trigger.Action['id']): Promise<DeleteTriggerAction.Response> {
    return API.delete(`${route}/action/${id}`);
};

export function cancelExecutionTrigger(trigger_log_id: Trigger.Log['id']): Promise<CancelExecutionTrigger.Response> {
    return API.put(`${route}/log/cancel/${trigger_log_id}`);
}