import React, { useEffect, useReducer, useState } from 'react';

import { App, Button, Col, Input, Row, Select } from 'antd';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { isFilledField, makeFilterOption } from 'lib/helpers/Form.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';
import { makeClientOptions } from 'pages/issue/Filters';
import { listSuppliers } from 'services/Supplier.service';
import { listUser } from 'services/UserService';
import type { OptionalField } from 'types/packages/antd';

type Filter = {
    client: OptionalField<Payment.Model['client_id']>,
    title: string | undefined,
    status: string | undefined,
    user: string | undefined,
    supplier: string | undefined,
}

const reducer = (state: Filter, newState: Partial<Filter>) => ({ ...state, ...newState });

const FILTER_DEFAULT_VALUE: Filter = {
    client: undefined,
    title: undefined,
    status: undefined,
    user: undefined,
    supplier: undefined,
};

const filterOption = makeFilterOption();

export function ServiceBudgetFilterBar() {
    const [filter, setFilter] = useReducer(reducer, FILTER_DEFAULT_VALUE);
    const [users, setUsers] = useState<User.Model[]>([]);
    const [suppliers, setSuppliers] = useState<Supplier.Model[]>([]);
    const [isSending, setIsSending] = useState(false);

    const [isFiltering, setIsFiltering] = useState(false);

    const app = App.useApp();

    const {
        serviceBudgets,
        setServiceBudgets,
        fetchServiceBudgets,
    } = useServiceBudget();

    const fetchUsers = async () => {
        const response = await listUser();

        if (!response.success)
            return handleServiceError(app, response);

        setUsers(response.users);
    };

    const fetchSuppliers = async () => {
        setIsSending(true);

        const response = await listSuppliers();

        if (!response.success)
            return;

        setSuppliers(response.suppliers);
        setIsSending(false);
    };

    const handleClear = () => {
        setIsFiltering(false);
        setFilter(FILTER_DEFAULT_VALUE);
        fetchServiceBudgets();
    };

    const handleFilter = () => {
        let filteredServiceBudgets = serviceBudgets;

        const {
            client,
            title,
            status,
            user,
            supplier
        } = filter;

        if (isFilledField<number>(client))
            filteredServiceBudgets = filteredServiceBudgets.filter(serviceBudget => serviceBudget.client_id === client);

        if (isFilledField<string>(title))
            filteredServiceBudgets = filteredServiceBudgets.filter(serviceBudget => serviceBudget.title.toLowerCase().includes(title.toLowerCase()));

        if (isFilledField<string>(status)) {
            filteredServiceBudgets = filteredServiceBudgets.filter(function (serviceBudget) {
                if (!serviceBudget.status)
                    serviceBudget.status = 'pending';

                return serviceBudget.status === status;
            });
        }

        if (isFilledField<string>(user))
            filteredServiceBudgets = filteredServiceBudgets.filter(serviceBudget => serviceBudget.who_id === parseInt(user));

        if (isFilledField<string>(supplier))
            filteredServiceBudgets = filteredServiceBudgets.filter(serviceBudget => serviceBudget.requests.find(request => request.supplier_id === parseInt(supplier)));

        setIsFiltering(true);
        setServiceBudgets(filteredServiceBudgets);
    };

    useEffect(() => {
        fetchUsers();
        fetchSuppliers();
    }, []);

    /** Disable the filter action when all filters are empty */
    const isActionButtonDisabled = Object.values(filter).every(value => value === undefined);

    const statuses = [
        { value: 'pending', label: 'Pendente' },
        { value: 'done', label: 'Finalizado' },
        { value: 'canceled', label: 'Cancelado' },
        { value: 'in_progress', label: 'Em progresso' },
        { value: 'awaiting_approval', label: 'Aguardando aprovação' },
        { value: 'awaiting_for_contract', label: 'Aguardando contrato' },
        { value: 'awaiting_signature', label: 'Aguardando assinatura' },
    ];

    const userOptions = users.map(user => ({ value: user.id, label: user.name }));
    const supplierOptions = suppliers.map(supplier => ({ value: supplier.id, label: supplier.name }));

    return (
        <Row gutter={[8, 8]}>
            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={makeClientOptions()}
                    value={filter.client}
                    onChange={value => setFilter({ client: value })}
                    onClear={() => setFilter({ client: undefined })}
                    placeholder="Condomínio"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={6} xs={24}>
                <Input
                    value={filter.title ?? undefined}
                    onChange={event => setFilter({ title: event.currentTarget.value })}
                    placeholder="Título"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={statuses}
                    value={filter.status}
                    onChange={value => setFilter({ status: value })}
                    onClear={() => setFilter({ status: undefined })}
                    placeholder="Status"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={userOptions}
                    value={filter.user}
                    onChange={value => setFilter({ user: value })}
                    onClear={() => setFilter({ user: undefined })}
                    placeholder="Responsável"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={supplierOptions}
                    value={filter.supplier}
                    onChange={value => setFilter({ supplier: value })}
                    onClear={() => setFilter({ supplier: undefined })}
                    placeholder="Fornecedor"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={2} xs={24}>
                <Button
                    block
                    type={isFiltering ? 'dashed' : 'primary'}
                    onClick={isFiltering ? handleClear : handleFilter}
                    icon={isFiltering ? <ClearOutlined /> : <FilterOutlined />}
                    title={isActionButtonDisabled ? 'Por favor, selecione ao menos um filtro.' : undefined}
                    disabled={isActionButtonDisabled}
                >
                    {isFiltering ? 'Limpar' : 'Filtrar'}
                </Button>
            </Col>
        </Row>
    );
}
