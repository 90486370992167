import React, { Fragment, useEffect, useState } from 'react';

import {
    App,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Select,
    SelectProps,
    UploadFile
} from 'antd';

import { Dayjs } from 'dayjs';
import Authentication from 'lib/Authentication';
import { DynamicSelect } from 'lib/DynamicSelect';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useDocument } from 'lib/providers/DocumentContextProvider';
import { sleep } from 'lib/Sleep';
import { UploadField } from 'lib/UploadField';
import { createDocument } from 'services/Document.service';
import { createSupplier, listSuppliers } from 'services/Supplier.service';

type Values = {
    client: Client.Model['id'],
    title: string,
    type: number,
    share_with_resident?: Document.Model['share_with_resident'],
    supplier_id?: Document.Model['supplier_id'],
    policy_number?: Document.Model['policy_number'],
    period?: [Dayjs, Dayjs],
    amount?: number,
    renewal?: 'auto' | 'manual',
    observations?: string,
    files: UploadFile[],
};

type Options = NonNullable<SelectProps['options']>;

const filterOption = makeFilterOption();

export function CreateDocumentModal() {
    const [isSending, setIsSending] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isContract, setIsContract] = useState<boolean>(false);
    const [isPolicy, setIsPolicy] = useState<boolean>(false);
    const [suppliers, setSuppliers] = useState<Supplier.Model[]>([]);

    const {
        setIsCreateModalVisible,
        fetchDocuments,
        types,
    } = useDocument();

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    const close = () => setIsCreateModalVisible(false);
    const watchedClientId = Form.useWatch('client', form);
    const watchedType = Form.useWatch('type', form);

    const clientHasApp = Authentication.getClientManager().contracted_plan === null || Authentication.getClientManager().contracted_plan.includes('app');

    const fetchData = async () => {
        if (!watchedClientId)
            return;

        setIsFetching(true);

        const promises = [
            listSuppliers([watchedClientId]),
        ] as const;

        const [
            suppliersResponse,
        ] = await Promise.all(promises);


        if (suppliersResponse.success) {
            setSuppliers(suppliersResponse.suppliers);
            setIsFetching(false);

            return suppliersResponse.suppliers;
        }

        return;
    };

    const handleNewSupplier = async (option: string) => {
        setIsFetching(true);

        // eslint-disable-next-line eqeqeq
        if (option == '') {
            setIsFetching(false);
            return app.notification.error({ message: 'Atenção', description: 'Descreva o fornecedor que deseja inserir' });
        }

        const clients = Authentication.getClients().map((client) => (client.id));

        const response = await createSupplier({ name: option, clientsToSync: clients });

        await sleep(1000);

        setIsFetching(false);

        app.notification.open(response);

        const suppliers = await fetchData();

        const newSupplier = suppliers?.find(supplier => supplier.name === option);

        form.setFieldValue('supplier_id', newSupplier?.id);
    };


    const onFinish = async (values: Values) => {

        if (values.files && values.files.some(file => file.status === 'uploading')) {
            return app.notification.error(
                {
                    message: 'Atenção',
                    description: 'Aguarde o término do upload dos arquivos!'
                }
            );
        }

        setIsSending(true);

        const parsedFiles = values.files.map(file => ({
            url: file.response,
            filename: file.name
        }));

        const response = await createDocument({
            client_id: values.client,
            title: values.title,
            document_type_id: values.type,
            share_with_resident: values.share_with_resident || 0,
            supplier_id: values.supplier_id ?? null,
            policy_number: values.policy_number ?? null,
            start_date: values.period ? values.period?.[0]?.format('YYYY-MM-DD') : null,
            end_date: values.period ? values.period?.[1]?.format('YYYY-MM-DD') : null,
            amount: values.amount ?? null,
            renewal: values.renewal ?? null,
            observations: values.observations ?? null,
            files: parsedFiles
        });

        // Slow down a little to improve UX
        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        close();
        fetchDocuments();
    };

    useEffect(() => {
        fetchData();
    }, [watchedClientId]);

    useEffect(() => {

        if (watchedType) {
            const type = types.find(({ id }) => id === watchedType);
            setIsContract(type?.name === 'Contrato');
            setIsPolicy(type?.name === 'Apólice de seguro');
        }
    }, [watchedType]);

    const parsedTypes: Options = types.map(({ id, name }) => ({ value: id, label: name }));
    const parsedSuppliers: Options = suppliers.map(({ id, name }) => ({ value: id, label: name }));

    const parsedClients: Options = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    return (
        <Modal
            title="Criar documento"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Criar"
            onCancel={close}
            cancelText="Cancelar"
            style={{ top: 10 }}
            open
        >
            <Form
                form={form}
                onFinish={onFinish}
                name="createDocument"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item<Values>
                    name="client"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select
                        options={parsedClients}
                        filterOption={filterOption}
                        showSearch
                    />
                </Form.Item>

                <Form.Item<Values>
                    name="type"
                    label="Tipo"
                    rules={[{ required: true, message: 'Por favor, selecione um tipo.' }]}
                >
                    <Select options={parsedTypes} />
                </Form.Item>

                <Form.Item<Values>
                    name="title"
                    label="Título"
                    rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item<Values>
                    name="supplier_id"
                    label="Fornecedor"
                >
                    <DynamicSelect
                        options={parsedSuppliers}
                        loading={isFetching}
                        dropdown={{ placeholder: 'Insira aqui um novo fornecedor', onNewOption: handleNewSupplier }}
                    />
                </Form.Item>

                {(isContract || isPolicy) && (
                    <Fragment>
                        {isPolicy && (
                            <Form.Item<Values>
                                name="policy_number"
                                label="Número da apólice"
                            >
                                <Input maxLength={50} />
                            </Form.Item>
                        )}

                        <Form.Item<Values>
                            name="period"
                            label="Período de vigência"
                        >
                            <DatePicker.RangePicker format={'DD/MM/YYYY'} />
                        </Form.Item>

                        <Form.Item<Values>
                            name="amount"
                            label="Valor"
                        >
                            <Input prefix="R$" type="number" />
                        </Form.Item>

                        <Form.Item<Values>
                            name="renewal"
                            label="Renovação"
                            help="Iremos avisar quando estiver próximo da data de renovação com no minimo 60 dias de antecedência."
                        >
                            <Select options={[{ value: 'auto', label: 'Automática' }, { value: 'manual', label: 'Manual' }]} />
                        </Form.Item>
                    </Fragment>
                )}

                {
                    clientHasApp && (
                        <Form.Item<Values>
                            name="share_with_resident"
                            label="Disponibilizar no aplicativo dos condôminos?"
                            rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                        >
                            <Radio.Group>
                                <Radio value={1}>Sim</Radio>
                                <Radio value={0}>Não</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )
                }

                <Form.Item<Values>
                    name="observations"
                    label="Observações"
                    help={
                        isContract
                            ? 'Utilize este campo para registrar informações adicionais sobre o contrato, como cláusulas importantes, condições especiais, histórico de alterações ou qualquer detalhe relevante que não esteja nos campos principais. Exemplo: ‘Contrato inclui reajuste anual pelo IPCA’ ou ‘Prazo de rescisão: 60 dias de antecedência’'
                            : isPolicy
                                ? 'Este campo pode ser usado para anotações importantes sobre a apólice, como exclusões de cobertura, procedimentos para acionamento em caso de sinistro, histórico de renovações ou qualquer detalhe relevante. Exemplo: ‘Cobertura inclui danos elétricos’ ou ‘Franquia de R$ 5.000,00 para acionamento’.'
                                : null
                    }
                >
                    <Input.TextArea />
                </Form.Item>

                <UploadField
                    name="files"
                    type="picture"
                    multiple={true}
                    rules={[{ required: true, message: 'Por favor, insira ao menos um arquivo.' }]}
                />
            </Form>
        </Modal>
    );
}
