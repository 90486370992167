import React from 'react';

import { type TableColumnsType, Tag } from 'antd';

import { CheckCircleOutlined, ClockCircleOutlined, CloseOutlined, ExclamationCircleOutlined, RiseOutlined } from '@ant-design/icons';
import { ServiceBudgetActionsCell } from 'components/ServiceBudget/ServiceBudgetActionsCell';
import dayjs from 'dayjs';

export const Columns: TableColumnsType<ServiceBudget.Model> = [
    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
        key: 'clientName',
        render: (_, record) => record.client?.name ?? '-',
    },
    {
        title: 'Título',
        dataIndex: 'title',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (value) => {
            const tag = { color: 'warning', value: 'Novo', icon: <ExclamationCircleOutlined /> };

            switch (value) {
            case 'canceled':
                tag.color = 'error';
                tag.icon = <CloseOutlined />;
                tag.value = 'Cancelado';
                break;
            case 'done':
                tag.color = 'success';
                tag.icon= <CheckCircleOutlined />;
                tag.value = 'Finalizado';
                break;
            case 'pending':
                tag.color = 'gold';
                tag.icon= <ClockCircleOutlined />;
                tag.value = 'Pendente';
                break;
            case 'in_progress':
                tag.color = 'blue';
                tag.icon= <RiseOutlined />;
                tag.value = 'Em progresso';
                break;
            case 'awaiting_approval':
                tag.color = 'processing';
                tag.icon= <ClockCircleOutlined />;
                tag.value = 'Aguardando aprovação';
                break;
            case 'awaiting_for_contract':
                tag.color = 'orange';
                tag.icon= <ClockCircleOutlined />;
                tag.value = 'Aguardando contrato';
                break;
            case 'awaiting_signature':
                tag.color = 'purple';
                tag.icon= <ClockCircleOutlined />;
                tag.value = 'Aguardando assinatura';
                break;
            }

            return <Tag icon={tag.icon} color={tag.color}>{tag.value}</Tag>;
        }
    },
    {
        title: 'Prazo',
        dataIndex: 'deadline',
        render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
        title: 'Ações',
        width: '100px',
        render: (_, serviceBudget) => <ServiceBudgetActionsCell serviceBudget={serviceBudget} />,
    },
];