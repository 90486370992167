import React, { useEffect, useState } from 'react';

import { Checkbox, Image, Space, Typography } from 'antd';

import { Show } from 'lib/Show';

type File = { filename: string, url: string };

type Props = {
    files: File[];
    onSelectionChange: (selected: string[]) => void;
};

const IMAGE_FILE_EXTENSIONS = ['jpg', 'png', 'jpeg'] as const;

const isValidImageFileExtension = (value: any) => IMAGE_FILE_EXTENSIONS.includes(value.toLowerCase());

const isValidImageFile = ({ url }: File) => {
    const lowerCaseUrl = url.toLowerCase();
    const splittedLowerCaseUrl = lowerCaseUrl.split('.');
    const extension = splittedLowerCaseUrl.length > 1
        ? splittedLowerCaseUrl[splittedLowerCaseUrl.length - 1]
        : null;

    return extension ? isValidImageFileExtension(extension) : false;
};

export function CheckboxImage({ files, onSelectionChange }: Props) {
    const [selectedImages, setSelectedImages] = useState<string[]>([]);

    const handleImageCheckboxChange = (id: string, checked: boolean) => {
        let updatedSelectedImages = [];

        if (checked) {
            updatedSelectedImages = [...selectedImages, id];
        } else {
            updatedSelectedImages = selectedImages.filter(imageId => imageId !== id);
        }

        setSelectedImages(updatedSelectedImages);
        onSelectionChange(updatedSelectedImages);
    };

    useEffect(() => {
        onSelectionChange(selectedImages);
    }, [selectedImages, onSelectionChange]);

    const images = files.filter(isValidImageFile);
    const others = files.filter(file => !isValidImageFile(file));

    const previews = (
        <Space direction="vertical">
            {images.map(({ url }, index) => (
                <Checkbox
                    key={index}
                    onChange={e => handleImageCheckboxChange(url, e.target.checked)}
                    checked={selectedImages.includes(url)}
                >
                    <Image
                        width={70}
                        height={70}
                        src={url}
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                    />
                </Checkbox>
            ))}
        </Space>
    );

    const links = (
        <Space direction="vertical">
            {others.map(({ url, filename }, index) => (
                <Checkbox
                    key={index}
                    onChange={e => handleImageCheckboxChange(url, e.target.checked)}
                    checked={selectedImages.includes(url)}
                >
                    <a href={url} target="_blank" rel="noreferrer">
                        {filename}
                    </a>
                </Checkbox>
            ))}
        </Space>
    );

    return (
        <Show when={images.length > 0 || others.length > 0}>
            <Space
                direction="vertical"
                size="middle"
                style={{
                    maxHeight: '20rem',
                    overflowY: 'auto',
                    paddingRight: '0.8rem',
                    marginBottom: '2rem'
                }}>
                <Typography.Text>
                    Selecione as imagens:
                </Typography.Text>
                {previews}
                {links}
            </Space >
        </Show>
    );
}
