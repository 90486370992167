import React, { useState } from 'react';

import { App, Dropdown, type MenuProps } from 'antd';

import { DeleteOutlined, EditOutlined, HistoryOutlined, ReloadOutlined } from '@ant-design/icons';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';

type Props = { serviceBudget: ServiceBudget.Model };

export function ServiceBudgetActionsCell({ serviceBudget }: Props) {
    const [isSending, setIsSending] = useState(false);

    const app = App.useApp();

    const {
        setServiceBudgetId,
        setIsEditModalVisible,
        setIsDetailModalVisible,
        removeServiceBudget,
        fetchServiceBudgets,
        setIsChangeStatusModalVisible,
        setIsHistoryModalVisible,
    } = useServiceBudget();

    const handleDetail = () => {
        setServiceBudgetId(serviceBudget.id);
        setIsDetailModalVisible(true);
    };

    const handleEdit = () => {
        setServiceBudgetId(serviceBudget.id);
        setIsEditModalVisible(true);
    };

    const handleChangeStatus = () => {
        setServiceBudgetId(serviceBudget.id);
        setIsChangeStatusModalVisible(true);
    };

    const handleHistory = () => {
        setServiceBudgetId(serviceBudget.id);
        setIsHistoryModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);
        await removeServiceBudget(serviceBudget.id);
        setIsSending(false);
        fetchServiceBudgets();
    };

    /** @factory */
    const makeItems = (
        handleEdit: () => void,
        handleDelete: () => void,
    ): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        if (serviceBudget.status !== 'done' && serviceBudget.status !== 'canceled') {            
            items.push(
                {
                    key: 'edit',
                    label: 'Editar',
                    icon: <EditOutlined />,
                    onClick: handleEdit,
                },
            );

            items.push(
                {
                    key: 'changeStatus',
                    label: 'Atualizar status',
                    icon: <ReloadOutlined />,
                    onClick: handleChangeStatus
                },
            );
        }

        items.push(
            {
                key: 'history',
                label: 'Histórico',
                icon: <HistoryOutlined />,
                onClick: handleHistory
            },
        );

        items.push(
            {
                key: 'delete',
                label: 'Excluir',
                icon: <DeleteOutlined />,
                onClick: () => app.modal.confirm({
                    title: 'Excluir chamado',
                    content: 'Tem certeza que deseja excluir este chamado?',
                    okText: 'Sim',
                    okType: 'danger',
                    onOk: handleDelete,
                    cancelText: 'Não',
                }),
                danger: true,
            }
        );

        return items;
    };

    const items = makeItems(
        handleEdit,
        handleDelete,
    );

    return (
        <Dropdown.Button
            trigger={['click']}
            menu={{ items, disabled: isSending }}
            onClick={handleDetail}
            loading={isSending}
        >
            Detalhes
        </Dropdown.Button>
    );
}
