import React, { Fragment, useEffect, useState } from 'react';

import { App, Button, Card, Col, Form, Input, Modal, Radio, Row, Select, SelectProps, Steps, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { actions, constructionStatus, issueStatus, maintenanceStatus } from 'lib/helpers/Trigger.helper';
import { useWhatsApp } from 'lib/providers/WhatsAppContextProvider';
import { Show } from 'lib/Show';
import { sleep } from 'lib/Sleep';
import { listIssueType } from 'services/Issue.service';
import { listMaintenaceType } from 'services/Maintenance.service';
import { listSuppliers } from 'services/Supplier.service';
import { createTrigger, createTriggerAction, createTriggerCondition } from 'services/Trigger.service';
import { listUser } from 'services/UserService';

const filter = makeFilterOption();

type Variable = {
    label: string,
    value: string,
};

type Module = {
    label: string,
    value: string,
    events: SelectProps['options'],
    conditions: Array<{ label: string, value: string, value_options: SelectProps['options'] }>,
    variables: Variable[],
};

type Condition = {
    field: string,
    condition: string,
    value: string
};

type RequestStatus = 'wait' | 'process' | 'finish';

export function NewTriggerModal() {
    const { setIsNewTriggerModalVisible, fetchTriggers } = useWhatsApp();

    const [isSaving, setIsSaving] = useState(false);
    const [isCreatingTrigger, setIsCreatingTrigger] = useState<RequestStatus>('wait');
    const [isCreatingTriggerAction, setIsCreatingTriggerAction] = useState<RequestStatus>('wait');
    const [isCreatingTriggerCondition, setIsCreatingTriggerCondition] = useState<RequestStatus>('wait');

    const [module, setModule] = useState<Module | undefined>();
    const [users, setUsers] = useState<SelectProps['options']>([]);
    const [suppliers, setSuppliers] = useState<SelectProps['options']>([]);
    const [issueTypes, setIssueTypes] = useState<SelectProps['options']>([]);
    const [maintenanceTypes, setMaintenanceTypes] = useState<SelectProps['options']>([]);

    const app = App.useApp();
    const [form] = Form.useForm();
    const moduleField = Form.useWatch('module', form);
    const conditions = Form.useWatch('conditions', form);
    const actionsField = Form.useWatch('actions', form);
    const messages = Form.useWatch('messages', form);

    const clients = [...Authentication.getClients().map(client => ({ label: client.name, value: client.id.toString() }))];
    const clientsId = Authentication.getClients().map(client => client.id);

    const fetchUsers = async () => {
        const response = await listUser();

        if (!response.success)
            return;

        setUsers(response.users.map(user => ({ label: user.name, value: user.id.toString() })));
    };

    const fetchIssueTypes = async () => {
        const response = await listIssueType();

        if (!response.success)
            return;

        setIssueTypes(response.issue_types.map(issueType => ({ label: issueType.name, value: issueType.id.toString() })));
    };

    const fetchMaintenanceTypes = async () => {
        const response = await listMaintenaceType();

        if (!response.success)
            return;

        setMaintenanceTypes(response.maintenance_types.map(maintenanceType => ({ label: maintenanceType.name, value: maintenanceType.id.toString() })));
    };

    const fetchSuppliers = async () => {
        const response = await listSuppliers(clientsId);

        if (!response.success)
            return;

        setSuppliers(response.suppliers.map(supplier => ({ label: supplier.name, value: supplier.id.toString() })));
    };

    useEffect(() => {
        if (module?.value !== moduleField) {
            setModule(modules.find(module => module.value === moduleField));
            form.setFieldsValue({ conditions: [] });
            form.setFieldValue('messages', {});
        }

    }, [form, moduleField, module]);

    useEffect(() => {
        fetchMaintenanceTypes();
        fetchIssueTypes();
        fetchSuppliers();
        fetchUsers();
    }, []);

    const addVariable = (variable: Variable, action: string) => {
        const message = messages[action].message + variable.value;
        form.setFieldsValue({ messages: { ...messages, [action]: { ...messages[action], message } } });
    };

    const onFinish = async (values: any) => {
        setIsSaving(true);
        setIsCreatingTrigger('process');

        const { name, description, module, event } = values;

        const responseCreateTrigger = await createTrigger({
            name,
            description,
            module,
            event
        });

        if (!responseCreateTrigger.success) {
            setIsCreatingTrigger('finish');
            setIsSaving(false);
            return app.notification.error({ message: 'Erro ao criar gatilho', description: responseCreateTrigger.message });
        }

        await sleep(2000);

        setIsCreatingTrigger('finish');

        const triggerId = responseCreateTrigger.trigger.id;
        const conditionsBody = values.conditions.map((condition: Condition) => ({ trigger_id: triggerId, ...condition }));

        setIsCreatingTriggerCondition('process');

        const responseCreateTriggerCondition = await createTriggerCondition(conditionsBody);

        if (!responseCreateTriggerCondition.success) {
            setIsCreatingTriggerCondition('finish');
            setIsSaving(false);
            return app.notification.error({ message: 'Erro ao criar condições', description: responseCreateTriggerCondition.message });
        }

        await sleep(2000);

        setIsCreatingTriggerCondition('finish');

        const actionsBody = values.actions.map((action: string) => ({ trigger_id: triggerId, action, ...values.messages[action] }));

        setIsCreatingTriggerAction('process');

        const responseCreateTriggerAction = await createTriggerAction(actionsBody);

        if (!responseCreateTriggerAction.success) {
            setIsCreatingTriggerAction('finish');
            setIsSaving(false);
            return app.notification.error({ message: 'Erro ao criar ações', description: responseCreateTriggerAction.message });
        }

        await sleep(2000);

        setIsCreatingTriggerAction('finish');

        fetchTriggers();

        await sleep(2000);

        setIsSaving(false);

        app.notification.success({ message: 'Gatilho criado com sucesso' });
        setIsNewTriggerModalVisible(false);
    };

    const modules: Module[] = [
        {
            label: 'Chamado',
            value: 'issue',
            events: [
                {
                    label: 'Criado(a)',
                    value: 'create'
                },
                {
                    label: 'Alterado(a)',
                    value: 'update'
                },
                {
                    label: 'Finalizado(a)',
                    value: 'finish'
                },
                {
                    label: 'Reaberto(a)',
                    value: 'reopen'
                },
                {
                    label: 'Atribuído(a)',
                    value: 'assign'
                },
            ],
            conditions: [
                {
                    label: 'Condomínio',
                    value: 'clientId',
                    value_options: clients
                },
                {
                    label: 'Categoria',
                    value: 'issueTypeId',
                    value_options: issueTypes
                },
                {
                    label: 'Responsável',
                    value: 'user_responsible_id',
                    value_options: users
                },
                {
                    label: 'Fornecedor',
                    value: 'supplier_id',
                    value_options: suppliers
                },
                {
                    label: 'Status',
                    value: 'status',
                    value_options: issueStatus
                }
            ],
            variables: [
                {
                    label: 'ID',
                    value: '{{id}}'
                },
                {
                    label: 'Assunto',
                    value: '{{subject}}'
                },
                {
                    label: 'Categoria',
                    value: '{{issue_type_name}}'
                },
                {
                    label: 'Status',
                    value: '{{status}}'
                },
                {
                    label: 'Prazo',
                    value: '{{deadline}}'
                },
                {
                    label: 'Data de resolução',
                    value: '{{resolutionDate}}'
                },
                {
                    label: 'Nome do responsável',
                    value: '{{user_responsible_name}}'
                },
                {
                    label: 'Nome do fornecedor',
                    value: '{{supplier_name}}'
                },
                {
                    label: 'Nome do condomínio',
                    value: '{{client_name}}'
                }
            ]
        },
        {
            label: 'Manutenção',
            value: 'maintenance',
            events: [
                {
                    label: 'Criado(a)',
                    value: 'create'
                },
                {
                    label: 'Alterado(a)',
                    value: 'update'
                },
                {
                    label: 'Finalizado(a)',
                    value: 'finish'
                },
                {
                    label: 'Reaberto(a)',
                    value: 'reopen'
                }
            ],
            conditions: [
                {
                    label: 'Condomínio',
                    value: 'clientId',
                    value_options: clients
                },
                {
                    label: 'Categoria',
                    value: 'maintenance_type_id',
                    value_options: maintenanceTypes
                },
                {
                    label: 'Fornecedor',
                    value: 'supplierId',
                    value_options: suppliers
                },
                {
                    label: 'Status',
                    value: 'status',
                    value_options: maintenanceStatus
                }
            ],
            variables: [
                {
                    label: 'ID',
                    value: '{{id}}'
                },
                {
                    label: 'Categoria',
                    value: '{{maintenance_type_name}}'
                },
                {
                    label: 'Data estimada para realização',
                    value: '{{estimatedDate}}'
                },
                {
                    label: 'Data de finalização',
                    value: '{{completionDate}}'
                },
                {
                    label: 'Nome do fornecedor',
                    value: '{{supplier_name}}'
                },
                {
                    label: 'Nome do condomínio',
                    value: '{{client_name}}'
                }
            ]
        },
        {
            label: 'Obras',
            value: 'construction',
            events: [
                {
                    label: 'Criado(a)',
                    value: 'create'
                },
                {
                    label: 'Alterado(a)',
                    value: 'update'
                }
            ],
            conditions: [
                {
                    label: 'Condomínio',
                    value: 'clientId',
                    value_options: clients
                },
                {
                    label: 'Status',
                    value: 'status',
                    value_options: constructionStatus
                }
            ],
            variables: [
                {
                    label: 'ID',
                    value: '{{id}}'
                },
                {
                    label: 'Título',
                    value: '{{name}}'
                },
                {
                    label: 'Status',
                    value: '{{status}}'
                },
                {
                    label: 'Nome do condomínio',
                    value: '{{client_name}}'
                }
            ]
        }
    ];

    return (
        <Modal
            destroyOnClose
            onCancel={() => setIsNewTriggerModalVisible(false)}
            title={isSaving ? 'Criando gatilho...' : 'Novo gatilho'}
            footer={false}
            style={isSaving ? undefined : { top: 10 }}
            centered={isSaving}
            width={isSaving ? '50%' : '80%'} open>
            <Show when={isSaving}>
                <Steps current={0}>
                    <Steps.Step title={isCreatingTrigger === 'process' ? 'Estamos criando o gatilho...' : 'Gatilho criado'} status={isCreatingTrigger} icon={isCreatingTrigger === 'process' ? <LoadingOutlined /> : undefined} />
                    <Steps.Step title={isCreatingTriggerCondition === 'process' ? 'Vinculando as condições do gatilho...' : 'Condições vinculadas'} status={isCreatingTriggerCondition} icon={isCreatingTriggerCondition === 'process' ? <LoadingOutlined /> : undefined} />
                    <Steps.Step title={isCreatingTriggerAction === 'process' ? 'Configurando suas ações' : 'Ações configuradas'} status={isCreatingTriggerAction} icon={isCreatingTriggerAction === 'process' ? <LoadingOutlined /> : undefined} />
                </Steps>
            </Show>
            <Show when={!isSaving}>
                <Form
                    form={form}
                    layout='vertical'
                    name='new_trigger'
                    onFinish={onFinish}
                >
                    <Row>
                        <Col span={12}>
                            <Card title="Configurações do evento" bordered={false}>
                                <Form.Item
                                    label='Nome'
                                    tooltip='Defina de forma sucinta o nome do gatilho'
                                    name='name'
                                    rules={[{ required: true, message: 'Nome é obrigatório' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label='Descrição'
                                    tooltip='Descreva o que o gatilho irá fazer com maior detalhes'
                                    name='description'>
                                    <TextArea />
                                </Form.Item>
                                <Form.Item
                                    label='Módulo'
                                    name='module'
                                    rules={[{ required: true, message: 'Módulo é obrigatório' }]}
                                >
                                    <Select filterOption={filter} showSearch allowClear>
                                        {modules.map(module => <Select.Option key={module.value} value={module.value}>{module.label}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label='Evento'
                                    name='event'
                                    rules={[{ required: true, message: 'Evento é obrigatório' }]}
                                >
                                    <Select filterOption={filter} showSearch allowClear>
                                        {module?.events?.map(event => <Select.Option key={event.value} value={event.value}>{event.label}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Condições do gatilho" bordered={false}>
                                <Form.List name="conditions">
                                    {(fields, { add, remove }) => (
                                        <Fragment>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Card title={`Condição ${key + 1}`} bordered={false} extra={<MinusCircleOutlined onClick={() => remove(name)} />}>
                                                    <Form.Item
                                                        {...restField}
                                                        label='Campo'
                                                        name={[name, 'field']}
                                                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                    >
                                                        <Select filterOption={filter} showSearch allowClear>
                                                            {module?.conditions.map(condition => <Select.Option key={condition.value} value={condition.value}>{condition.label}</Select.Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label='Condição'
                                                        name={[name, 'condition']}
                                                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                    >
                                                        <Select filterOption={filter} showSearch allowClear>
                                                            <Select.Option value="equal">Igual</Select.Option>
                                                            <Select.Option value="equal_previous">Era igual</Select.Option>
                                                            <Select.Option value="diff">Diferente</Select.Option>
                                                            <Select.Option value="diff_previous">Era diferente</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label='Valor'
                                                        name={[name, 'value']}
                                                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                    >
                                                        <Select filterOption={filter} showSearch allowClear>
                                                            {module?.conditions.find(condition => conditions && condition.value === conditions?.[key]?.field)?.value_options?.map(value_option => <Select.Option key={value_option.value} value={value_option.value}>{value_option.label}</Select.Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </Card>
                                            ))}
                                            <Form.Item>
                                                <Button disabled={!module} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Adicionar condição
                                                </Button>
                                            </Form.Item>
                                        </Fragment>
                                    )}
                                </Form.List>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Card title="Ações do gatilho" bordered={false}>
                                <Form.List name="actions">
                                    {(fields, { add, remove }) => (
                                        <Fragment>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Card title={`Ação ${key + 1}`} bordered={false} style={{ marginBottom: 10 }} extra={<MinusCircleOutlined onClick={() => remove(name)} />}>
                                                    <Form.Item
                                                        {...restField}
                                                        label='Enviar WhatsApp para'
                                                        name={[name]}
                                                        rules={[{ required: true, message: 'Ação é obrigatório' }]}
                                                    >
                                                        <Select filterOption={filter} showSearch allowClear>
                                                            {actions.map(action => <Select.Option key={action.value} value={action.value}>{action.label}</Select.Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </Card>
                                            ))}
                                            <Form.Item>
                                                <Button disabled={!module} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Adicionar ação
                                                </Button>
                                            </Form.Item>
                                        </Fragment>
                                    )}
                                </Form.List>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Mensagem" bordered={false}>
                                {actionsField?.filter((action: string) => action !== undefined).map((action: string, index: number) => (
                                    <Card title={action} bordered={false} style={{ marginBottom: 10 }}>
                                        <Form.Item key={`varibles_${index}`} label="Variáveis disponíveis para uso">
                                            {module?.variables.map(variable => (
                                                <Tag style={{ cursor: 'pointer' }} onClick={() => addVariable(variable, action)}>{variable.label}</Tag>
                                            ))}
                                        </Form.Item>
                                        <Form.Item
                                            key={`message_${index}`}
                                            label={<span>Mensagem para <strong>{actions.find(a => a.value === action)?.label}</strong></span>}
                                            name={['messages', action, 'message']}
                                            rules={[{ required: true, message: 'Mensagem é obrigatório' }]}>
                                            <TextArea rows={5} />
                                        </Form.Item>
                                        {/* <Form.Item
                                            key={`files_${index}`}
                                            name={['messages', action, 'files']}
                                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                                            label={<span>Deseja anexar as fotos e documentos do(a) <strong>{module?.label}</strong> se houver?</span>}>
                                            <Radio.Group>
                                                <Radio value={1}>Sim, anexar fotos e documentos</Radio>
                                                <Radio value={0}>Não</Radio>
                                            </Radio.Group>
                                        </Form.Item> */}
                                        {
                                            module?.value !== 'maintenance' && (
                                                <Form.Item
                                                    key={`files_${index}`}
                                                    name={['messages', action, 'report']}
                                                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                    label={<span>Deseja gerar o relatório do(a) <strong>{module?.label}</strong> e anexar à mensagem?</span>}>
                                                    <Radio.Group>
                                                        <Radio value={1}>Sim, anexar o relatório</Radio>
                                                        <Radio value={0}>Não</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            )
                                        }
                                    </Card>
                                ))}
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={null}>
                                <Button type="primary" htmlType="submit">
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Show>
        </Modal>
    );
}
