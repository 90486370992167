import React, { Fragment, useState } from 'react';

import { Button, Card, Col, Descriptions, notification, Result, Row, Select, Table, TableColumnsType, Tabs, Typography } from 'antd';

import { CheckCircleOutlined, CloseCircleOutlined, ReloadOutlined, ThunderboltOutlined } from '@ant-design/icons';
import Content from 'components/_Common/_Layout/Content';
import { ListTriggerLogModal } from 'components/WhatsApp/ListTriggerLogModal';
import { NewTriggerModal } from 'components/WhatsApp/NewTriggerModal';
import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { WhatsAppContextProvider } from 'lib/providers/WhatsAppContextProvider';
import { Show } from 'lib/Show';
import { updateAccountClient } from 'services/Account.service';

import { TRIGGER_COLUMNS } from './Columns';

const filter = makeFilterOption();
const renderDoesNotHaveIntegration = () => {
    const clientManager = Authentication.getClientManager();
    const supportNumber = clientManager.contract === 'trial'
        ? 'https://wa.me/555191639123'
        : 'https://wa.me/5551991503329';


    return (
        <Card bordered={false}>
            <Result
                status="info"
                title="Conta não habilitada"
                subTitle="Sua conta não está habilitada para utilizar a integração com o WhatsApp. Se você tem interesse em utilizar, entre em contato com nossa equipe comercial para realizar a contração."
            >
                <div className="desc">
                    <Typography.Paragraph>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 16,
                            }}
                        >
                            Quais são as vantagens em utilizar a integração com o WhatsApp?
                        </Typography.Text>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <CheckCircleOutlined className="site-result-demo-error-icon" /> Enviar retorno de chamados atendidos via WhatsApp para os seus clientes.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <CheckCircleOutlined className="site-result-demo-error-icon" /> Enviar mensagens diretamente nos grupos de WhatsApp dos seus condomínios.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <CheckCircleOutlined className="site-result-demo-error-icon" /> Criar rotinas de disparos com atualizações do seu trabalho para seus clientes.
                    </Typography.Paragraph>
                </div>
                <Button type="primary" onClick={() => window.open(supportNumber, '_blank')}>
                    Entre em contato
                </Button>
            </Result>
        </Card>);
};

export function WhatsApp() {
    const [currentTab, setCurrentTab] = useState('1');
    const clients = Authentication.getClients();
    const client_manager = Authentication.getClientManager();
    const hasWhatsappIntegration = client_manager.zapi_id_instance !== null && client_manager.zapi_id_instance !== '';

    return (
        <WhatsAppContextProvider>
            {({ connected, smartphoneConnected, qrcode, groups, isLoading, isTriggersLoading, triggers, isTriggerLogModalVisible, isNewTriggerModalVisible, setIsNewTriggerModalVisible }) => {
                const updateClientWhatsGroup = async (clientId: number, whatsapp_group_id: string) => {
                    const response = await updateAccountClient(clientId, { whatsapp_group_id });

                    if (!response.success)
                        return;

                    notification.success({ message: 'Atualizado com sucesso!' });
                    Authentication.syncAllowedClientsUser();
                };

                const updateClientWhatsWorkgroup = async (clientId: number, whatsapp_workgroup_id: string) => {
                    const response = await updateAccountClient(clientId, { whatsapp_workgroup_id });

                    if (!response.success)
                        return;

                    notification.success({ message: 'Atualizado com sucesso!' });
                    Authentication.syncAllowedClientsUser();
                };

                const COLUMNS: TableColumnsType<Client.Model> = [
                    {
                        title: 'Nome',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Grupo de condôminos',
                        dataIndex: 'whatsapp_group_id',
                        width: '30%',
                        render: (value, client) => <Select
                            defaultValue={value}
                            onChange={(value) => updateClientWhatsGroup(client.id, value)}
                            style={{ width: '100%' }}
                            filterOption={filter}
                            options={groups}
                            showSearch
                            allowClear />
                    },
                    {
                        title: 'Grupo de trabalho',
                        dataIndex: 'whatsapp_workgroup_id',
                        width: '30%',
                        render: (value, client) => <Select
                            defaultValue={value}
                            onChange={(value) => updateClientWhatsWorkgroup(client.id, value)}
                            style={{ width: '100%' }}
                            filterOption={filter}
                            options={groups}
                            showSearch
                            allowClear />
                    }
                ];

                const renderWhatsAppIntegrationStatus = () => {
                    return (
                        <Card bordered={false} loading={isLoading}>
                            <Row>
                                <Col span={14}>
                                    <Card bordered={false}>
                                        <Descriptions title="Status da Integração" bordered>
                                            <Descriptions.Item label="Conectado" span={3}>
                                                {connected ? <span><CheckCircleOutlined style={{ color: 'green' }} /> Conectado ao sistema</span> : <span><CloseCircleOutlined style={{ color: 'red' }} /> Desconectado</span>}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Dispositivo conectado à internet" span={3}>
                                                {smartphoneConnected ? <span><CheckCircleOutlined style={{ color: 'green' }} /> Conectado</span> : <span><CloseCircleOutlined style={{ color: 'red' }} /> Sem acesso à internet</span>}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Card>
                                </Col>
                                <Col span={10}>
                                    <Card title='Leia o QR Code' bordered={false}>
                                        {(qrcode && !connected) && (
                                            <Fragment>
                                                <Row>
                                                    <Typography.Text>
                                                        Abra o aplicativo do WhatsApp e leia o QRCode abaixo para se conectar com o sistema
                                                    </Typography.Text>
                                                </Row>
                                                <Row justify={'center'}>
                                                    <img src={qrcode} alt="QRCode" />
                                                </Row>
                                                <Row justify={'center'}>
                                                    <Typography.Text type='secondary'>
                                                        O QRCode expira em 30 segundos, clique no botão abaixo para recarregar. Caso já tenha lido o QRCode, clique em recarregar para atualizar o status.
                                                    </Typography.Text>
                                                    <Button type="text" icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
                                                        Atualizar
                                                    </Button>
                                                </Row>
                                            </Fragment>
                                        )}
                                        {connected && (
                                            <Result
                                                status="success"
                                                title="Conectado"
                                                subTitle="Você está conectado ao WhatsApp"
                                            />
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    );
                };

                const renderWhatsAppGroups = () => {
                    return (
                        <Card bordered={false} loading={isLoading}>
                            <Card title="Configurações" bordered={false}>
                                <Tabs
                                    activeKey={currentTab}
                                    onChange={(key) => setCurrentTab(key)}
                                    tabBarExtraContent={<span>
                                        {/* <Button type='default' icon={<ToolOutlined />}>Nova rotina</Button>
                                    <Divider type='vertical' /> */}
                                        <Button type='default' icon={<ThunderboltOutlined />} onClick={() => {
                                            setCurrentTab('2');
                                            setIsNewTriggerModalVisible(true);
                                        }}>Novo gatilho</Button>
                                    </span>}>
                                    <Tabs.TabPane tab="Grupos de WhatsApp" key="1">
                                        <Table
                                            loading={isLoading}
                                            bordered
                                            size='small'
                                            columns={COLUMNS}
                                            dataSource={clients}
                                            pagination={false}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Gatilhos" key="2">
                                        <Table
                                            loading={isTriggersLoading}
                                            bordered
                                            size='small'
                                            columns={TRIGGER_COLUMNS}
                                            dataSource={triggers}
                                            pagination={false}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </Card>
                        </Card>
                    );
                };

                return (
                    <Fragment>
                        <Content>
                            <Row>
                                <Col span={24}>
                                    <Typography.Title level={3}>
                                        WhatsApp Integration
                                    </Typography.Title>
                                    <Typography.Text>Neste módulo você irá conectar o seu WhatsApp ao nosso sistema e potencializar ainda mais a sua comunicação e gestão.</Typography.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {
                                        !hasWhatsappIntegration && renderDoesNotHaveIntegration()
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {
                                        hasWhatsappIntegration && renderWhatsAppIntegrationStatus()
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {
                                        (hasWhatsappIntegration && connected) && renderWhatsAppGroups()
                                    }
                                </Col>
                            </Row>
                            <Show when={isNewTriggerModalVisible}>
                                <NewTriggerModal />
                            </Show>

                            <Show when={isTriggerLogModalVisible}>
                                <ListTriggerLogModal />
                            </Show>
                        </Content>
                    </Fragment>
                );
            }}
        </WhatsAppContextProvider>
    );
}
