import React from 'react';

import {
    Button,
    Drawer,
    Form,
    Input,
    InputNumber,
    Space,
} from 'antd';

import { FilesGalery } from 'components/_Common/FilesGalery';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';
import { UploadField } from 'lib/UploadField';
import { editRequestServiceBudget } from 'services/ServiceBudget.service';

export type Values = {
    title: string,
    description: string,
    justification: string,
    price: number | undefined,
    newFiles?: NewFile[],
    files: ServiceBudget.ServiceBudgetFile[] | undefined,
};

type NewFile = {
    name: string,
    response: string,
}

export function EditRequestServiceBudgetModal() {
    const {
        serviceBudget,
        serviceBudgetOption,
        setIsEditServiceBudgetOptionVisible,
        isEditServiceBudgetOptionVisible,
        fetchServiceBudgets,
    } = useServiceBudget();

    if (serviceBudget === null)
        throw new TypeError('Null value was provided to `notice` constant!');

    const close = () => setIsEditServiceBudgetOptionVisible(false);

    const [form] = Form.useForm<Values>();

    const onFinish = async () => {

        if (!serviceBudgetOption) {
            throw new Error('Requested service budget option not found!');
        }

        const values = await form.validateFields();

        const newFilesArray = values?.newFiles?.map(file => {
            return {
                filename: file.name,
                url: file.response,
            };
        });

        const body = {
            serviceBudgetId: serviceBudget.id,
            justification: values.justification,
            price: values.price,
            files: newFilesArray,
        };

        await editRequestServiceBudget(serviceBudgetOption.id, body);

        fetchServiceBudgets();
        close();
    };

    const initialValues: Values = {
        title: serviceBudget.title,
        description: serviceBudget.description.replace(/<[^>]*>/g, ''),
        files: serviceBudgetOption?.files,
        justification: serviceBudget.justification,
        price: serviceBudgetOption?.price
    };

    return (
        <Drawer
            open={isEditServiceBudgetOptionVisible}
            width={500}
            onClose={() => setIsEditServiceBudgetOptionVisible(false)}
            title="Editar solicitação"
        >
            <Form
                form={form}
                onFinish={onFinish}
                name="editServiceBudget"
                layout="vertical"
                autoComplete="off"
                initialValues={initialValues}
            >
                <Form.Item
                    name="title"
                    label="Assunto"
                    rules={[{ required: true, message: 'Por favor, digite algo.' }]}
                >
                    <Input maxLength={40} disabled/>
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Descrição do orçamento"
                    tooltip="O conteúdo deste campo irá compor o email ao solicitar orçamentos pelo sistema."
                    rules={[{ required: true, message: 'Por favor, digite algo.' }]}
                >
                    <Input maxLength={200} disabled/>
                </Form.Item>

                <Form.Item
                    name="files"
                >
                    <FilesGalery files={serviceBudgetOption ? serviceBudgetOption.files : []}/>
                </Form.Item>

                <UploadField
                    name="newFiles"
                    type="picture"
                    multiple
                    buttonText='Inserir novo arquivo'
                />
                    
                <Form.Item
                    name="price"
                    label="Preço"
                    rules={[{ required: true, message: 'Por favor, selecione o preço.' }]}
                >
                    <InputNumber
                        addonBefore="R$"
                        decimalSeparator=","
                        min={1}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item
                    name="justification"
                    label="Observações"
                >
                    <Input maxLength={200} />
                </Form.Item>

                <Space>
                    <Button onClick={() => setIsEditServiceBudgetOptionVisible(false)}>Cancelar</Button>
                    <Button onClick={onFinish} type="primary">
                        Salvar
                    </Button>
                </Space>
            </Form>
        </Drawer>
    );
}
