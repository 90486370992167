import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import {
    App,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    SelectProps,
    Switch,
    UploadFile,
} from 'antd';

import dayjs, { type Dayjs } from 'dayjs';
import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';
import { Show } from 'lib/Show';
import { UploadField } from 'lib/UploadField';
import { listUser } from 'services/UserService';

import { updateServiceBudget } from '../../services/ServiceBudget.service';

export type Values = {
    title: ServiceBudget.Model['title'],
    description: ServiceBudget.Model['description'],
    type: ServiceBudget.Model['type'],
    reference: ServiceBudget.Model['reference'],
    contract_signing: ServiceBudget.Model['contract_signing'],
    issue_ids: Array<number>,
    maintenance_ids: Array<number>,
    client_id: number,
    files?: Array<UploadFile>,
    user_ids: Array<number>,
    construction_ids: Array<number>,
    deadline: ServiceBudget.Model['deadline'] | Dayjs,
    who_id: User.Model['id'] | null,
};

type SelectOptions = NonNullable<SelectProps['options']>;

export function EditServiceBudgetModal() {
    const [isSending, setIsSending] = useState(false);
    const [reference, setReference] = useState<string>('');
    const [users, setUsers] = useState<User.Model[]>([]);

    const {
        issues,
        maintenances,
        serviceBudget,
        constructions,
        setIsEditModalVisible,
        fetchServiceBudgets,
        clientId,
        setClientId,
    } = useServiceBudget();

    if (serviceBudget === null)
        throw new TypeError('Null value was provided to `notice` constant!');

    const close = async () => {
        setIsEditModalVisible(false);
        setClientId(undefined);
        setReference('');
    };

    const [form] = Form.useForm<Values>();

    const app = App.useApp();

    const fetchUsers = async () => {
        const parsedClientId = clientId ? [clientId] : [];
        const response = await listUser(parsedClientId);

        if (!response.success)
            return handleServiceError(app, response);

        setUsers(response.users);
    };

    useEffect(() => {
        setUsers([]);
        form.resetFields(['user_ids']);

        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    useEffect(() => {
        setClientId(serviceBudget.client_id);

        setReference(serviceBudget.reference);
        fetchUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFinish = async ({
        files,
        ...restValues
    }: Values) => {
        setIsSending(true);

        const parsedFiles = files?.map(file => ({ url: file.response || file.url, filename: file.name }));

        const body: Parameters<typeof updateServiceBudget>['1'] = {
            files: parsedFiles ?? [],
            ...restValues,
        };

        const response = await updateServiceBudget(serviceBudget.id, body);

        setIsSending(false);

        app.notification.open(response);

        fetchServiceBudgets();

        close();
    };

    const clients: SelectOptions = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    const filterOption = makeFilterOption();


    const parsedReferences = [
        { value: 'call', label: 'Chamados' },
        { value: 'maintenance', label: 'Manutenções' },
        { value: 'construction', label: 'Obras' },
    ];

    const parsedTypes = [
        { value: 'service', label: 'Serviço' },
        { value: 'buy', label: 'Compras' },
    ];

    const files: UploadFile[] = serviceBudget.files.map(({ url, filename }) => ({
        uid: url,
        url: url,
        name: filename,
        status: 'done',
    }));

    useEffect(() => {
        const userOptionIds = users.map(user => user.id);

        if (userOptionIds.length > 0) {
            form.setFieldsValue({
                user_ids: serviceBudget.users
                    .filter(user => userOptionIds.includes(user.id))
                    .map(user => user.id),
            });
        }
    }, [users, serviceBudget.users, form]);

    const initialValues: Values = {
        title: serviceBudget.title,
        description: serviceBudget.description,
        issue_ids: serviceBudget.issues.map(i => i.id),
        maintenance_ids: serviceBudget.maintenances.map(m => m.id),
        client_id: serviceBudget.client_id,
        type: serviceBudget.type,
        reference: serviceBudget.reference,
        contract_signing: serviceBudget.contract_signing,
        files,
        user_ids: [],
        construction_ids: serviceBudget.constructions.map(construction => construction.id),
        deadline: serviceBudget.deadline !== null ? dayjs(serviceBudget.deadline) : '',
        who_id: serviceBudget.who?.id ?? null,
    };

    return (
        <Modal
            open
            centered
            width={1200}
            title="Editar orçamento"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="editServiceBudget"
                layout="vertical"
                autoComplete="off"
                initialValues={initialValues}
            >
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="client_id"
                            label="Condomínio"
                        >
                            <Select 
                                options={clients} 
                                filterOption={filterOption} 
                                onChange={(e) => setClientId(e)} 
                            />
                        </Form.Item>

                        <Form.Item<Values>
                            name="who_id"
                            label="Usuário responsável:"
                        >
                            <Select
                                allowClear
                                optionFilterProp="children"
                            >
                                {users.map(user => (
                                    <Select.Option value={user.id}>
                                        {`#${user.id} ${user.name}`}
                                    </Select.Option>)
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item name="type" label="Tipo de orçamento:" rules={[{ required: true, message: 'Selecione o tipo de orçamento' }]}>
                            <Select options={parsedTypes} />
                        </Form.Item>

                        <Form.Item
                            name="title"
                            label="Título:"
                            rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                        >
                            <Input maxLength={40} />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Descrição do orçamento:"
                            tooltip="O conteúdo deste campo irá compor o email ao solicitar orçamentos pelo sistema."
                            rules={[{ required: true, message: 'Por favor, digite algo.' }]}
                        >
                            <ReactQuill theme="snow" />

                        </Form.Item>
                        <Show when={clientId !== undefined && clientId !== null}>
                            <Form.Item name="reference" label="Referência:">
                                <Select options={parsedReferences} onChange={(event) => setReference(event)} />
                            </Form.Item>
                        </Show>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="contract_signing"
                            label="Necessária assinatura de contrato?"
                        >
                            <Switch />
                        </Form.Item>

                        <Show when={reference === 'call'}>
                            <Form.Item
                                name="issue_ids"
                                label="Chamados relacionados:"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    disabled={!clientId}
                                    optionFilterProp="children"
                                >
                                    {issues.map(i => (
                                        <Select.Option value={i.id}>
                                            {`#${i.id} ${i.issue_type.name} - ${i.subject}`}
                                        </Select.Option>)
                                    )}
                                </Select>
                            </Form.Item>
                        </Show>

                        <Show when={reference === 'maintenance'}>
                            <Form.Item
                                name="maintenance_ids"
                                label="Manutenções relacionados:"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    disabled={!clientId}
                                    optionFilterProp="children"
                                >
                                    {maintenances.map(m => (
                                        <Select.Option value={m.id}>
                                            {`${m.maintenance_type.name} (${dayjs(m.estimatedDate).format('DD/MM/YYYY')})`}
                                        </Select.Option>)
                                    )}
                                </Select>
                            </Form.Item>
                        </Show>

                        <Show when={reference === 'construction'}>
                            <Form.Item
                                name="construction_ids"
                                label="Obras relacionadas:"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    disabled={!clientId}
                                    optionFilterProp="children"
                                >
                                    {constructions.map(construction => (
                                        <Select.Option value={construction.id}>
                                            {`#${construction.id} ${construction.name}`}
                                        </Select.Option>)
                                    )}
                                </Select>
                            </Form.Item>
                        </Show>

                        <Form.Item
                            name="user_ids"
                            label="Selecione os usuários que devem fazer parte deste orçamento (Cópia):"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                optionFilterProp="children"
                            >
                                {users.map(user => (
                                    <Select.Option value={user.id}>
                                        {`#${user.id} ${user.name}`}
                                    </Select.Option>)
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="deadline"
                            label="Defina um prazo:"
                            rules={[{ required: true, message: 'Por favor, selecione uma data.' }]}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>

                        <UploadField name='files' type='picture' multiple />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
