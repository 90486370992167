import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Layout, Menu } from 'antd';

import {
    BellOutlined,
    BuildOutlined,
    CalendarOutlined,
    CarOutlined,
    FieldTimeOutlined,
    FileTextOutlined,
    FormatPainterOutlined,
    HomeOutlined,
    NotificationOutlined,
    ReadOutlined,
    RobotOutlined,
    ScheduleOutlined,
    SettingOutlined,
    SnippetsOutlined,
    UserOutlined
} from '@ant-design/icons';
import Authentication from 'lib/Authentication';
import { hasAuthRouteAccess } from 'lib/helpers/Router.helper';

import { AuthRoutes } from '../_Routes/Routes';
import { HeaderLogo } from './HeaderLogo';

type State = {
    collapsed: Boolean,
    basePath: string
};
class Sider extends Component<RouteComponentProps, State> {

    state = {
        collapsed: false,
        basePath: '',
    };

    onCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    UNSAFE_componentWillMount = () => {
        this.setState({ basePath: this.props.location.pathname.split('/').slice(0, 2).join('/') });
        const mobileMediaQuery = window.matchMedia('(max-width: 768px)');

        if (mobileMediaQuery.matches)
            this.setState({ collapsed: true });
    };

    /** @see https://ant.design/components/menu#notes-for-developers */
    get items() {
        const items = [];

        const profile = Authentication.getUserProfile();

        if (hasAuthRouteAccess(profile, AuthRoutes.dashboard))
            items.push(
                <Menu.Item key={AuthRoutes.dashboard}>
                    <HomeOutlined />
                    <span>Dashboard</span>
                    <Link to={AuthRoutes.dashboard} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.concierge))
            items.push(
                <Menu.Item key={AuthRoutes.concierge}>
                    <HomeOutlined />
                    <span>Página inicial</span>
                    <Link to={AuthRoutes.concierge} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.apartment))
            items.push(
                <Menu.Item key={AuthRoutes.apartment}>
                    <BuildOutlined />
                    <span>Unidades</span>
                    <Link to={AuthRoutes.apartment} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.resident) && hasAuthRouteAccess(profile, AuthRoutes.vehicle))
            items.push(
                <Menu.SubMenu
                    key="resident_and_vehicle"
                    title={(
                        <span>
                            <UserOutlined />
                            <span>Condôminos</span>
                        </span>
                    )}
                >
                    <Menu.Item key={AuthRoutes.resident}>
                        <span>Pessoas</span>
                        <Link to={AuthRoutes.resident} />
                    </Menu.Item>

                    <Menu.Item key={AuthRoutes.vehicle}>
                        <span>Veículos</span>
                        <Link to={AuthRoutes.vehicle} />
                    </Menu.Item>
                </Menu.SubMenu>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.resident) && !hasAuthRouteAccess(profile, AuthRoutes.vehicle))
            items.push(
                <Menu.Item key={AuthRoutes.resident}>
                    <UserOutlined />
                    <span>Pessoas</span>
                    <Link to={AuthRoutes.resident} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.vehicle) && !hasAuthRouteAccess(profile, AuthRoutes.resident))
            items.push(
                <Menu.Item key={AuthRoutes.vehicle}>
                    <CarOutlined />
                    <span>Veículos</span>
                    <Link to={AuthRoutes.vehicle} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.issue))
            items.push(
                <Menu.Item key={AuthRoutes.issue}>
                    <SnippetsOutlined />
                    <span>Chamados</span>
                    <Link to={AuthRoutes.issue} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.maintenance))
            items.push(
                <Menu.Item key={AuthRoutes.maintenance}>
                    <SettingOutlined />
                    <span>Manutenções</span>
                    <Link to={AuthRoutes.maintenance} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.construction))
            items.push(
                <Menu.Item key={AuthRoutes.construction}>
                    <FormatPainterOutlined />
                    <span>Obras</span>
                    <Link to={AuthRoutes.construction} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.schedule))
            items.push(
                <Menu.Item key={AuthRoutes.schedule}>
                    <ScheduleOutlined />
                    <span>Cronograma</span>
                    <Link to={AuthRoutes.schedule} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.general_meeting))
            items.push(
                <Menu.Item key={AuthRoutes.general_meeting}>
                    <NotificationOutlined />
                    <span>Enquetes</span>
                    <Link to={AuthRoutes.general_meeting} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.notice))
            items.push(
                <Menu.Item key={AuthRoutes.notice}>
                    <BellOutlined />
                    <span>Avisos</span>
                    <Link to={AuthRoutes.notice} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.ambient) && hasAuthRouteAccess(profile, AuthRoutes.reserve))
            items.push(
                <Menu.SubMenu
                    key="reserves_and_ambients"
                    title={(
                        <span>
                            <CalendarOutlined />
                            <span>Reservas</span>
                        </span>
                    )}
                >
                    <Menu.Item key={AuthRoutes.ambient}>
                        <span>Ambientes</span>
                        <Link to={AuthRoutes.ambient} />
                    </Menu.Item>

                    <Menu.Item key={AuthRoutes.reserve}>
                        <span>Calendário</span>
                        <Link to={AuthRoutes.reserve} />
                    </Menu.Item>
                </Menu.SubMenu>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.ambient) && !hasAuthRouteAccess(profile, AuthRoutes.reserve))
            items.push(
                <Menu.Item key={AuthRoutes.ambient}>
                    <CalendarOutlined />
                    <span>Ambientes</span>
                    <Link to={AuthRoutes.ambient} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.reserve) && !hasAuthRouteAccess(profile, AuthRoutes.ambient))
            items.push(
                <Menu.Item key={AuthRoutes.reserve}>
                    <CalendarOutlined />
                    <span>Calendário</span>
                    <Link to={AuthRoutes.reserve} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.chatbot))
            items.push(
                <Menu.Item key={AuthRoutes.chatbot}>
                    <RobotOutlined />
                    <span>ChatBot</span>
                    <Link to={AuthRoutes.chatbot} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.document))
            items.push(
                <Menu.Item key={AuthRoutes.document}>
                    <FileTextOutlined />
                    <span>Documentos</span>
                    <Link to={AuthRoutes.document} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.consumption))
            items.push(
                <Menu.Item key={AuthRoutes.consumption}>
                    <ReadOutlined />
                    <span>Consumos</span>
                    <Link to={AuthRoutes.consumption} />
                </Menu.Item>
            );

        if (hasAuthRouteAccess(profile, AuthRoutes.employee))
            items.push(
                <Menu.Item key={AuthRoutes.employee}>
                    <FieldTimeOutlined />
                    <span>Controle de férias</span>
                    <Link to={AuthRoutes.employee} />
                </Menu.Item>
            );

        return items;
    }

    render() {
        const { basePath } = this.state;

        return (
            <Layout.Sider
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
                style={{ minHeight: '100vh' }}
            >
                <HeaderLogo />

                <Menu
                    style={{ marginTop: 30 }}
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[basePath]}
                >
                    {this.items}
                </Menu>
            </Layout.Sider >
        );
    }
}

export default withRouter(Sider);