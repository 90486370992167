import API from '../lib/API';
import { type ChangeStatus,CreateServiceBudget, CreateServiceBudgetOption, DeleteServiceBudget, type EditServiceBudgetOption,FindServiceBudget, ListServiceBudgets, type ParsedFile,UpdateServiceBudget } from './contracts/ServiceBudget.contract';

const route = '/service_budget';

export function listServiceBudgets(): Promise<ListServiceBudgets.Response> {
    return API.get(route);
};

export function findServiceBudget(id: ServiceBudget.Model['id']): Promise<FindServiceBudget.Response> {
    return API.get(`${route}/${id}`);
};

export function createServiceBudget(body: CreateServiceBudget.Body): Promise<CreateServiceBudget.Response> {
    return API.post(route, body);
};

export function updateServiceBudget(id: ServiceBudget.Model['id'], body: UpdateServiceBudget.Body): Promise<UpdateServiceBudget.Response> {
    return API.put(`${route}/${id}`, body);
};

export function changeStatusServiceBudget(
    id: ServiceBudget.Model['id'], 
    newStatus: ServiceBudget.Model['status'], 
    comment: string,
    whoId: User.Model['id'] | null,
): Promise<ChangeStatus.Response> {
    const extraInfo = { 
        newStatus: newStatus, 
        comment: comment,
        whoId: whoId,
    };

    return API.patch(`${route}/${id}/change-status`, extraInfo);
};

export function uploadFile(body: ParsedFile): Promise<ChangeStatus.Response> {
    return API.post('/default/ServiceBudgetProgressFile', body);
};

export function approvedServiceBudgetOption(id: ServiceBudget.Request['id']): Promise<UpdateServiceBudget.Response> {
    return API.patch(`${route}/approved/${id}`);
};

export function deleteServiceBudget(id: ServiceBudget.Model['id']): Promise<DeleteServiceBudget.Response> {
    return API.delete(`${route}/${id}`);
};

export function createRequestServiceBudget(body: CreateServiceBudgetOption.Body): Promise<CreateServiceBudgetOption.Response> {
    return API.post(`${route}/option`, body);
};

export function editRequestServiceBudget(id: number, body: EditServiceBudgetOption.Body): Promise<EditServiceBudgetOption.Response> {
    return API.put(`${route}/option/${id}`, body);
};