import React, { useState } from 'react';

import { App, Button, notification, Popconfirm, Space, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { DeleteOutlined, EditOutlined, UndoOutlined, UnlockOutlined } from '@ant-design/icons';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { sleep } from 'lib/Sleep';
import { removeUser, resetUserPassword, restoreUser } from 'services/UserService';

type Props = {
    setOpen: Function,
    setUserId: Function,
    user: User.Model,
    fetchData: Function,
};

const UserActionsCell = ({ setUserId, setOpen, user, fetchData: fetchUsers }: Props) => {
    const [isPopResetconfirmVisible, setIsPopResetPasswordVisible] = useState(false);
    const [isPopDeleteUserVisible, setIsPopDeleteUserVisible] = useState(false);
    const [isPopRestoreUserVisible, setIsPopRestoreUserVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const app = App.useApp();

    const handleEdit = () => {
        setUserId(user.id);
        setOpen(true);
    };

    const handleResetPassword = async () => {
        setIsSending(true);

        const response = await resetUserPassword(user.id);

        // Slowed down a bit to improve UX
        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);
        setIsPopResetPasswordVisible(false);
        fetchUsers();
    };

    const handleDelete = async () => {
        setIsSending(true);

        const response = await removeUser(user.id);

        setIsSending(false);

        notification.open(response);
        setIsPopDeleteUserVisible(false);
        fetchUsers();
    };

    const handleRestore = async () => {
        setIsSending(true);

        const response = await restoreUser(user.id, { deleted_at: null });

        setIsSending(false);

        notification.open(response);
        setIsPopRestoreUserVisible(false);
        fetchUsers();
    };

    if (!Authorization.hasAccess(FEATURE['USER::RESET_PASSWORD']))
        return (
            <Button
                type="text"
                icon={<EditOutlined />}
                title="Editar"
                onClick={handleEdit}
            />
        );

    if (user.deleted_at !== null)
        return (
            <Space size="middle" className="user-actions-space">
                <Popconfirm
                    title="Restaurar usuário?"
                    description="Tem certeza que deseja restaurar o usuário?"
                    open={isPopRestoreUserVisible}
                    placement="left"
                    cancelText="Não"
                    okText="Sim"
                    okType="primary"
                    okButtonProps={{ loading: isSending }}
                    onConfirm={handleRestore}
                    onCancel={() => setIsPopRestoreUserVisible(false)}
                >
                    <Button
                        type="text"
                        icon={<UndoOutlined />}
                        title="Restaurar"
                        onClick={() => setIsPopRestoreUserVisible(true)}
                    />
                </Popconfirm>
            </Space>
        );

    return (
        <Space size="middle" className="user-actions-space">
            <Button
                type="text"
                icon={<EditOutlined />}
                title="Editar"
                onClick={handleEdit}
            />

            <Popconfirm
                title="Redefinir senha"
                description="Tem certeza que deseja redefinir a senha do usuário?"
                open={isPopResetconfirmVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isSending }}
                onConfirm={handleResetPassword}
                onCancel={() => setIsPopResetPasswordVisible(false)}
            >
                <Button
                    type="text"
                    icon={<UnlockOutlined />}
                    title="Redefinir senha"
                    onClick={() => setIsPopResetPasswordVisible(true)}
                />
            </Popconfirm>

            <Popconfirm
                title="Excluir usuário?"
                description="Tem certeza que deseja excluir o usuário?"
                open={isPopDeleteUserVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isSending }}
                onConfirm={handleDelete}
                onCancel={() => setIsPopDeleteUserVisible(false)}
            >
                <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    title="Excluir"
                    onClick={() => setIsPopDeleteUserVisible(true)}
                />
            </Popconfirm>
        </Space>
    );
};

export function getColumns(setOpen: Function, setUserId: Function, fetchData: Function) {
    const Columns: ColumnsType<User.Model> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'email'
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },

        {
            title: 'Status',
            dataIndex: 'deleted_at',
            key: 'deleted_at',
            render: (deleted_at) => <Tag color={deleted_at ? 'red' : 'green'}>{deleted_at ? 'Inativo' : 'Ativo'}</Tag>
        },

        {
            key: 'action',
            width: '200px',
            render: (_: any, user) => (
                <UserActionsCell
                    setOpen={setOpen}
                    setUserId={setUserId}
                    user={user}
                    fetchData={fetchData}
                />
            )
        }
    ];

    return Columns;
};