import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import {
    App,
    Col,
    DatePicker,
    Divider,
    Form,
    FormItemProps,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    SelectProps,
    Switch,
    UploadFile,
} from 'antd';

import { CheckboxImage } from 'components/_Common/CheckboxImage';
import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';
import { Show } from 'lib/Show';
import { UploadField } from 'lib/UploadField';
import { listSuppliers } from 'services/Supplier.service';
import { listUser } from 'services/UserService';

import { createServiceBudget } from '../../services/ServiceBudget.service';

export type Values = {
    title: ServiceBudget.Model['title'],
    description: ServiceBudget.Model['description'],
    files?: Array<UploadFile>,
    who_id: User.Model['id'],
};

type File = {
    filename: string,
    url: string,
}

type SelectOptions = NonNullable<SelectProps['options']>;

export function CreateServiceBudgetModal() {
    const [isSending, setIsSending] = useState(false);
    const [users, setUsers] = useState<User.Model[]>([]);
    const [reference, setReference] = useState<string>('');
    const [suppliers, setSuppliers] = useState<Supplier.Model[]>([]);
    const [filesIsVisible, setFilesIsVisible] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [allFiles, setFiles] = useState<File[]>([]);

    const {
        issues,
        maintenances,
        constructions,
        setIsCreateModalVisible,
        fetchServiceBudgets,
        clientId,
        setClientId,
    } = useServiceBudget();

    const close = async () => {
        setIsCreateModalVisible(false);
        setClientId(undefined);
        setReference('');
    };

    const [form] = Form.useForm<Values>();

    const app = App.useApp();

    const handleSelectionChange = (selected: string[]) => {
        setSelectedFiles(selected);
    };

    const fetchUsers = async () => {
        const parsedClientId = clientId ? [clientId] : [];
        const response = await listUser(parsedClientId);

        if (!response.success)
            return handleServiceError(app, response);

        setUsers(response.users);
    };

    const fetchSuppliers = async () => {
        setIsSending(true);

        const parsedClientId = clientId !== undefined ? [clientId] : [];

        const response = await listSuppliers(parsedClientId);

        if (!response.success)
            return;

        setSuppliers(response.suppliers);
        setIsSending(false);
    };

    useEffect(() => {
        setUsers([]);
        form.resetFields(['user_ids']);

        fetchUsers();
        fetchSuppliers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    const handleFileOptions = async () => {
        if (reference === 'call' && form.getFieldValue('issue_ids')) {
            const issuesMap = issues.filter(issue => form.getFieldValue('issue_ids').includes(issue.id));
            const filesIssueMap = issuesMap.flatMap(issue =>
                issue.issue_file?.map(file => ({
                    filename: file.filename,
                    url: file.url,
                })) || []
            ).filter(file => file.filename && file.url);

            const files: File[] = [...filesIssueMap];
            
            setFiles(files);
        }

        if (reference === 'maintenance' && form.getFieldValue('maintenance_ids')) {
            const maintenancesMap = maintenances.filter(maintenance => form.getFieldValue('maintenance_ids').includes(maintenance.id));
            const filesMaintenanceMap = maintenancesMap.flatMap(maintenance =>
                maintenance.files?.map(file => ({
                    filename: file.filename,
                    url: file.url,
                })) || []
            ).filter(file => file.filename && file.url);

            const files: File[] = [...filesMaintenanceMap];

            setFiles(files);
        }
    };

    const onFinish = async ({
        files,
        ...restValues
    }: Values) => {
        setIsSending(true);

        const allFilesSelected = allFiles.filter(file => selectedFiles.includes(file.url));
        const parsedFiles = files?.map(file => ({ url: file.response, filename: file.name })) || [];

        const mergedFiles = [...allFilesSelected, ...parsedFiles];

        const body: Parameters<typeof createServiceBudget>['0'] = {
            files: mergedFiles ?? [],
            ...restValues,
        };

        const response = await createServiceBudget(body);

        setIsSending(false);

        app.notification.open(response);

        fetchServiceBudgets();

        close();
    };

    const clients: SelectOptions = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    const filterOption = makeFilterOption();

    /** @see https://github.com/ant-design/ant-design/issues/21563 */
    const normFile: FormItemProps['getValueFromEvent'] = (event) => {
        if (Array.isArray(event))
            return event;

        return event?.fileList;
    };

    const parsedReferences = [
        { value: 'call', label: 'Chamados' },
        { value: 'maintenance', label: 'Manutenções' },
        { value: 'construction', label: 'Obras' },
    ];

    const parsedTypes = [
        { value: 'service', label: 'Serviço' },
        { value: 'buy', label: 'Compras' },
    ];

    return (
        <Modal
            open
            centered
            width={1200}
            title="Criar orçamento"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Criar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createServiceBudget"
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="client_id"
                            label="Condomínio:"
                        >
                            <Select options={clients} filterOption={filterOption} onChange={(e) => setClientId(e)} />
                        </Form.Item>

                        <Form.Item
                            name="who_id"
                            label="Usuário responsável:"
                            rules={[{ required: true, message: 'Selecione o usuário responsável.' }]}
                        >
                            <Select
                                allowClear
                            >
                                {users.map(user => (
                                    <Select.Option value={user.id}>
                                        {`#${user.id} ${user.name}`}
                                    </Select.Option>)
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item name="type" label="Tipo de orçamento:" rules={[{ required: true, message: 'Selecione o tipo de orçamento' }]}>
                            <Select options={parsedTypes} />
                        </Form.Item>

                        <Form.Item
                            name="title"
                            label="Título:"
                            rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                        >
                            <Input maxLength={40} />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Descrição do orçamento:"
                            tooltip="O conteúdo deste campo irá compor o email ao solicitar orçamentos pelo sistema."
                            rules={[{ required: true, message: 'Por favor, digite algo.' }]}
                        >
                            <ReactQuill theme="snow" />
                        </Form.Item>

                        <Show when={clientId !== undefined}>
                            <Form.Item name="reference" label="Referência:">
                                <Select options={parsedReferences} onChange={(event) => setReference(event)} />
                            </Form.Item>
                        </Show>
                        
                        <Show when={reference === 'call'}>
                            <Form.Item
                                name="issue_ids"
                                label="Chamados relacionados:"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    disabled={!clientId}
                                    optionFilterProp="children"
                                    onChange={() => handleFileOptions()}
                                >
                                    {issues.map(i => (
                                        <Select.Option value={i.id}>
                                            {`#${i.id} ${i.issue_type.name} - ${i.subject}`}
                                        </Select.Option>)
                                    )}
                                </Select>
                            </Form.Item>
                        </Show>

                        <Show when={reference === 'maintenance'}>
                            <Form.Item
                                name="maintenance_ids"
                                label="Manutenções relacionadas:"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    optionFilterProp="children"
                                    disabled={!clientId}
                                    onChange={() => handleFileOptions()}
                                >
                                    {maintenances.map(m => (
                                        <Select.Option value={m.id}>
                                            {`${m.maintenance_type.name} (${dayjs(m.estimatedDate).format('DD/MM/YYYY')})`}
                                        </Select.Option>)
                                    )}
                                </Select>
                            </Form.Item>
                        </Show>

                        <Show when={reference === 'construction'}>
                            <Form.Item
                                name="construction_ids"
                                label="Obras relacionadas:"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    optionFilterProp="children"
                                    disabled={!clientId}
                                >
                                    {constructions.map(construction => (
                                        <Select.Option value={construction.id}>
                                            {`#${construction.id} ${construction.name}`}
                                        </Select.Option>)
                                    )}
                                </Select>
                            </Form.Item>
                        </Show>

                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="contract_signing"
                            label="Necessária assinatura de contrato?"
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            name="user_ids"
                            label="Selecione os usuários que devem fazer parte deste orçamento (Cópia):"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                optionFilterProp="children"
                            >
                                {users.map(user => (
                                    <Select.Option value={user.id}>
                                        {`#${user.id} ${user.name}`}
                                    </Select.Option>)
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="suppliers"
                            label="Fornecedores:"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                optionFilterProp="children"
                            >
                                {suppliers
                                    .filter(supplier => supplier.email !== null)
                                    .map(supplier => (
                                        <Select.Option key={supplier.id} value={supplier.id}>
                                            {`${supplier.name}`}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="deadline"
                            label="Defina um prazo:"
                            rules={[{ required: true, message: 'Por favor, selecione uma data.' }]}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            name="external_files"
                            label="Deseja anexar os arquivos dos chamados e manutenções vinculados?"
                            rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                        >
                            <Radio.Group onChange={(e) => setFilesIsVisible(e.target.value)}>
                                <Radio value={1}>Sim</Radio>
                                <Radio value={0}>Não</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Show when={filesIsVisible}>
                            <CheckboxImage files={allFiles} onSelectionChange={handleSelectionChange} />
                        </Show>

                        <UploadField name='files' type='picture' multiple />
                    </Col>

                </Row>

            </Form>
        </Modal>
    );
}
