import React from 'react';

import {
    App,
    Button,
    Card,
    Col,
    Collapse,
    Modal,
    Popconfirm,
    Row,
    Tag,
    Typography,
    UploadFile
} from 'antd';

import { CheckCircleOutlined, CheckCircleTwoTone, ClockCircleOutlined, CloseOutlined, EditTwoTone, ExclamationCircleOutlined, RiseOutlined } from '@ant-design/icons';
import { FilesGalery } from 'components/_Common/FilesGalery';
import { formatBRL } from 'lib/helpers/BRL';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';
import { Show } from 'lib/Show';
import { approvedServiceBudgetOption } from 'services/ServiceBudget.service';

import { EditRequestServiceBudgetModal } from './EditRequestServiceBudgetModal';
import HistoryModal from './HistoryModal';

export type Values = {
    title: ServiceBudget.Model['title'],
    description: ServiceBudget.Model['description'],
    issue_ids: Array<number>,
    maintenance_ids: Array<number>,
    files?: Array<UploadFile>,
};

export function DetailServiceBudgetModal() {
    const {
        serviceBudget,
        setIsDetailModalVisible,
        setIsRequestModalVisible,
        setIsEditServiceBudgetOptionVisible,
        isEditServiceBudgetOptionVisible,
        setServiceBudgetOption,
        fetchServiceBudgets,
        setServiceBudgetId,
        setIsChangeStatusModalVisible,
        isHistoryModalVisible,
        setIsHistoryModalVisible
    } = useServiceBudget();

    const app = App.useApp();

    if (serviceBudget === null)
        throw new TypeError('Null value was provided to `notice` constant!');

    const items = serviceBudget.issues.map((i, key) => ({
        key: `${i.id}${key}`,
        label: `${i.issue_type.name} #${i.id} - ${i.subject}`,
        children: <>
            <p>{i.description}</p>
            <FilesGalery files={i.issue_file} />
        </>
    }));

    const statusTag = () => {
        const tag = { color: 'warning', value: 'Novo', icon: <ExclamationCircleOutlined /> };

        switch (serviceBudget.status) {
        case 'canceled':
            tag.color = 'error';
            tag.icon = <CloseOutlined />;
            tag.value = 'Cancelado';
            break;
        case 'done':
            tag.color = 'success';
            tag.icon = <CheckCircleOutlined />;
            tag.value = 'Finalizado';
            break;
        case 'pending':
            tag.color = 'gold';
            tag.icon = <ClockCircleOutlined />;
            tag.value = 'Pendente';
            break;
        case 'in_progress':
            tag.color = 'blue';
            tag.icon = <RiseOutlined />;
            tag.value = 'Em progresso';
            break;
        case 'awaiting_approval':
            tag.color = 'processing';
            tag.icon = <ClockCircleOutlined />;
            tag.value = 'Aguardando aprovação';
            break;
        case 'awaiting_for_contract':
            tag.color = 'orange';
            tag.icon = <ClockCircleOutlined />;
            tag.value = 'Aguardando contrato';
            break;
        case 'awaiting_signature':
            tag.color = 'purple';
            tag.icon = <ClockCircleOutlined />;
            tag.value = 'Aguardando assinatura';
            break;
        }

        return <Tag icon={tag.icon} color={tag.color}>{tag.value}</Tag>;
    };


    const usersLinkeds = [
        {
            key: 'users',
            label: 'Usuários vinculados',
            children: (
                <ul>
                    {serviceBudget.users.map(user => (
                        <li key={user.id}>{user.name} #{user.id}</li>
                    ))}
                </ul>
            ),
        }
    ];

    const handleEditServiceBudgetOption = (requestId: number) => {
        const serviceBudgetOption = serviceBudget.requests.find(request => request.id === requestId);

        if (!serviceBudgetOption) {
            throw new TypeError('Request not found in current quote!');
        }

        setIsEditServiceBudgetOptionVisible(true);
        setServiceBudgetOption(serviceBudgetOption);
    };

    const formattedPrice = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const handleApproveServiceBudget = async (requestId: number) => {
        const serviceBudgetOption = serviceBudget.requests.find(request => request.id === requestId);

        if (!serviceBudgetOption) {
            throw new TypeError('Request not found in current quote!');
        }

        const response = await approvedServiceBudgetOption(serviceBudgetOption.id);
        app.notification.success({ message: response.message, description: response.description });

        fetchServiceBudgets();
        setIsChangeStatusModalVisible(true);
    };

    const requests = serviceBudget.requests.map((request, key) => ({
        key: `${request.id}${key}`,
        label: (
            <span>
                {request.supplier.name} ({formatBRL(request.price)})
                {request.id === serviceBudget.request_approved?.id && <Tag color="success" icon={<CheckCircleOutlined />} style={{ marginLeft: 8 }}>Aprovado</Tag>}
            </span>
        ),
        children: (
            <>
                <p>Preço: {formattedPrice.format(request.price)}</p>
                <FilesGalery files={request.files} />
            </>
        ),
        extra: (
            <span>
                <Show when={request.id !== serviceBudget.request_approved?.id && serviceBudget.status === 'awaiting_approval'}>
                    <Popconfirm
                        title="Aprovar orçamento"
                        description="Você tem certeza que deseja aprovar o orçamento com esta solicitação?"
                        onConfirm={() => handleApproveServiceBudget(request.id)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <CheckCircleTwoTone
                            title="Aprovar orçamento"
                            style={{ marginRight: 12 }}
                        />
                    </Popconfirm>
                </Show>

                <Show when={request.price === 0}>
                    <Tag color="orange">Pendente</Tag>
                </Show>

                <Show when={serviceBudget.status !== 'done' && serviceBudget.status !== 'canceled'}>
                    <EditTwoTone
                        onClick={() => handleEditServiceBudgetOption(request.id)}
                        title='Editar'
                        style={{ marginRight: 8 }}
                    />
                </Show>
            </span>
        ),
    }));

    items.push(...serviceBudget.maintenances.map((m, key) => ({
        key: `${m.id}${key}`,
        label: `Manutenção #${m.id}`,
        children: <>
            {m.description}
        </>
    })));

    items.push(...serviceBudget.constructions.map((m, key) => ({
        key: `${m.id}${key}`,
        label: `Obra #${m.id} ${m.name}`,
        children: <>
            <p>Nome: {m.name}</p>
            <p>Descrição: {m.description}</p>
        </>
    })));

    const handleChangeStatus = () => {
        setServiceBudgetId(serviceBudget.id);
        setIsChangeStatusModalVisible(true);
    };

    const close = () => {
        setIsDetailModalVisible(false);
        setServiceBudgetId(null);
    };

    return (
        <Modal
            open
            centered
            width={1000}
            onCancel={close}
            footer={false}
        >
            <Typography.Title level={3}>
                Orçamento #{serviceBudget.id} - {serviceBudget.title}
            </Typography.Title>

            {statusTag()}

            <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card bordered={false}>

                        <Typography.Paragraph
                            ellipsis={{
                                rows: 3,
                                tooltip: serviceBudget.description.replace(/<[^>]*>/g, '')
                            }}
                        >
                            <strong>Descrição: </strong>
                            {serviceBudget.description.replace(/<[^>]*>/g, '')}
                        </Typography.Paragraph>

                        <Show when={serviceBudget.who !== null}>
                            <Typography.Paragraph>
                                <strong>Responsável: </strong>
                                {serviceBudget.who?.name}
                            </Typography.Paragraph>
                        </Show>

                        <Show when={items.length > 0}>
                            <Typography.Paragraph style={{ margin: 0, marginBottom: 10 }}><strong>Referências vinculadas:</strong></Typography.Paragraph>
                            <Collapse items={items} style={{ maxHeight: 'auto', overflowY: 'auto', marginBottom: 10 }} />
                        </Show>

                        <Show when={serviceBudget.users.length > 0}>
                            <Typography.Paragraph style={{ margin: 0, marginBottom: 10, marginTop: 10 }}><strong>Usuários vinculados:</strong></Typography.Paragraph>
                            <Collapse
                                items={usersLinkeds}
                                style={{ maxHeight: 'auto', overflowY: 'auto', marginBottom: 10 }}
                            />
                        </Show>

                        <FilesGalery files={serviceBudget.files} />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Row
                        justify={serviceBudget.status !== 'done' && serviceBudget.status !== 'canceled' ? 'space-between' : 'end'}
                        align="middle"
                        gutter={[10, 10]}
                        style={{ marginBottom: 16 }}
                    >
                        <Show when={serviceBudget.status !== 'done' && serviceBudget.status !== 'canceled'}>
                            <Col>
                                <Button onClick={() => handleChangeStatus()}>
                                    Atualizar status
                                </Button>
                            </Col>
                        </Show>

                        <Show when={serviceBudget.status === 'pending' || serviceBudget.status === 'in_progress'}>
                            <Col>
                                <Button type="primary" onClick={() => setIsRequestModalVisible(true)}>
                                    Cadastrar solicitação
                                </Button>
                            </Col>
                        </Show>

                        <Col>
                            <Button type='dashed' onClick={() => setIsHistoryModalVisible(true)}>
                                Histórico
                            </Button>
                        </Col>
                    </Row>

                    <Show when={requests.length > 0}>
                        <Typography.Paragraph style={{ marginBottom: 10 }}>
                            <strong>Solicitações:</strong>
                        </Typography.Paragraph>

                        <Collapse
                            items={requests}
                            style={{ maxHeight: '30rem', overflowY: 'auto', borderRadius: 8 }}
                        />
                    </Show>

                    <Show when={isEditServiceBudgetOptionVisible}>
                        <EditRequestServiceBudgetModal />
                    </Show>
                    <Show when={isHistoryModalVisible}>
                        <HistoryModal />
                    </Show>
                </Col>
            </Row>
        </Modal>
    );
}
