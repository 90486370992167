import React, { useEffect, useState } from 'react';

import { App, Form, Modal ,Select, type UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';
import { UploadField } from 'lib/UploadField';
import type { ParsedFile } from 'services/contracts/ServiceBudget.contract';
import { changeStatusServiceBudget, uploadFile } from 'services/ServiceBudget.service';
import { listUser } from 'services/UserService';

type Values = {
    new_status: ServiceBudget.Model['status'],
    comment: string,
    who_id: User.Model['id'],
    files: UploadFile[],
};

export default function ChangeStatusModal() {
    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);
    const [users, setUsers] = useState<User.Model[]>([]);

    const {
        serviceBudget,
        setIsChangeStatusModalVisible,
        fetchServiceBudgets,
    } = useServiceBudget();

    const close = () => {
        setIsChangeStatusModalVisible(false);
        setSaving(false);
        form.resetFields();
    };

    const app = App.useApp();

    useEffect(() => {
        const fetchUsers = async () => {
            const parsedClientId = serviceBudget?.client_id ? [serviceBudget.client_id] : [];
            const response = await listUser(parsedClientId);

            if (!response.success) return handleServiceError(app, response);

            setUsers(response.users);
        };

        fetchUsers();
    }, [app, serviceBudget?.client_id]);

    async function onFinish() {
        if (!serviceBudget) {
            throw new Error('Something went wrong! Service budget not found.');
        }

        try {
            setSaving(true);
            const values = await form.validateFields();

            const response = await changeStatusServiceBudget(
                serviceBudget.id, 
                values.new_status, 
                values.comment,
                values.who_id ?? null,
            );

            if (values.files) {
                const parsedFiles: ParsedFile[] =
                    values.files.map((file: UploadFile): ParsedFile => ({
                        filename: file.name,
                        url: file.response ?? file.url,
                        service_budget_progress_id: response.service_budget_progress_id,
                    })
                    );
    
                await Promise.all(parsedFiles.map(file => uploadFile(file)));
            }

            await fetchServiceBudgets();

            close();
            setSaving(false);
        } catch (error) {
            setSaving(false);
        }
    };

    const getStatusOptions = (serviceBudget: ServiceBudget.Model | null) => {
        const options = [];
    
        if (serviceBudget?.status === 'pending' && serviceBudget?.requests.length > 0) {
            options.push({ value: 'in_progress', label: 'Em progresso' });
        }
    
        const isPendingAndInProgress = serviceBudget?.status === 'pending' || serviceBudget?.status === 'in_progress';

        const hasPendingRequests = serviceBudget!.requests.length > 0
            ? serviceBudget?.requests?.some((request: ServiceBudget.Request) => request.price === 0)
            : true;

        if (isPendingAndInProgress && !hasPendingRequests) {
            options.push({ value: 'awaiting_approval', label: 'Enviar para aprovação' });
        }
    
        if (
            (serviceBudget?.status === 'awaiting_approval' || serviceBudget?.status === 'awaiting_signature')
            && serviceBudget.request_approved !== null
            && serviceBudget.contract_signing
        ) {
            options.push({ value: 'awaiting_for_contract', label: 'Solicitar contrato' });
        }
    
        if (serviceBudget?.status === 'awaiting_for_contract') {
            options.push({ value: 'awaiting_signature', label: 'Enviar para assinatura' });
        }
    
        const pendingApprovalWithoutContract = (serviceBudget?.status === 'awaiting_approval' && !serviceBudget?.contract_signing);

        if (
            (serviceBudget?.status === 'awaiting_signature' || pendingApprovalWithoutContract)
            && serviceBudget.request_approved !== null
        ) {
            options.push({ value: 'done', label: 'Finalizado' });
        }
    
        options.push({ value: 'canceled', label: 'Cancelado' });
    
        return options;
    };

    return (
        <Modal
            centered
            title="Atualizar status"
            destroyOnClose={true}
            confirmLoading={isSaving}
            onOk={onFinish}
            width={800}
            onCancel={close}
            afterClose={() => form.resetFields()}
            okText="Salvar"
            open
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item<Values> 
                    label="Novo status:" 
                    rules={[{ required: true }]} 
                    name="new_status" 
                    style={{ marginBottom: '1.5rem' }}
                >
                    <Select
                        placeholder= 'Selecione um novo status'
                    >
                        {getStatusOptions(serviceBudget).map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item<Values> 
                    name="comment"
                    label="Descrição:"
                    style={{ marginBottom: '16px' }}
                >
                    <TextArea />
                </Form.Item>

                <Form.Item<Values>
                    name="who_id"
                    label="Usuário responsável:"
                    initialValue={serviceBudget?.who?.id}
                >
                    <Select
                        allowClear
                    >
                        {users.map(user => (
                            <Select.Option value={user.id}>
                                {`#${user.id} ${user.name}`}
                            </Select.Option>)
                        )}
                    </Select>
                </Form.Item>

                <UploadField name='files' type='picture' multiple />
            </Form>
        </Modal >
    );

};