import React, { CSSProperties, Fragment, useEffect, useState } from 'react';

import { Avatar, Badge, Button, Col, Collapse, Drawer, List, Popover, Row, Typography } from 'antd';

import { ClockCircleOutlined, CloseCircleOutlined, EditOutlined, FileOutlined, HistoryOutlined, SettingOutlined, SnippetsOutlined } from '@ant-design/icons';
import Content from 'components/_Common/_Layout/Content';
import { AuthRoute, AuthRoutes } from 'components/_Common/_Routes/Routes';
import { PendingInvoicesModal } from 'components/CobreFacil/PendingInvoicesModal';
import { ListDocumentModal } from 'components/Document/ListDocumentModal';
import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { getUserInitials } from 'lib/helpers/Issue.helper';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { Indicator } from 'lib/Indicator';
import { LoadingOverview, OverviewContextProvider } from 'lib/providers/OverviewContextProvider';
import { Show } from 'lib/Show';
import { listInvoices } from 'services/CobreFacil.service';
import { ActivitiesForToday, GetUserIssues, RecentActivities } from 'services/contracts/Overview.contract';

const wl = makeWhiteLabel();
const style: CSSProperties = { color: wl.colorSecondary };

export function Overview() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [invoices, setInvoices] = useState<CobreFacil.Invoice[]>([]);
    const [open, setOpen] = useState(false);

    const userName = Authentication.getUserName();
    const clientManager = Authentication.getClientManager();

    /** @factory */
    const makeUrlWithParameter = (route: AuthRoute, parameter: string, value: string): string => {
        const url = `${route}?${parameter}=${value}`;

        return url;
    };

    const makeActions = (item: ActivitiesForToday.Data) => {
        if (item.model === 'issue')
            return <Button href={makeUrlWithParameter(AuthRoutes.overviewIssue, 'detail', item.id.toString())} target='_blank'>
                Detalhes
            </Button>;

        return <Button href={makeUrlWithParameter(AuthRoutes.overviewMaintenance, 'finish', item.id.toString())} target='_blank'>
            Finalizar
        </Button>;
    };

    const makeRecentActions = (item: RecentActivities.Data) => {
        if (item.model === 'issue')
            return <Button href={makeUrlWithParameter(AuthRoutes.overviewIssue, 'detail', item.id.toString())} target='_blank'>
                Detalhes
            </Button>;

        if (item.model === 'maintenance' && item.status)
            return <Button href={makeUrlWithParameter(AuthRoutes.overviewMaintenance, 'detail', item.id.toString())} target='_blank'>
                Detalhes
            </Button>;

        if (item.model === 'maintenance' && !item.status)
            return <Button href={makeUrlWithParameter(AuthRoutes.overviewMaintenance, 'finish', item.id.toString())} target='_blank'>
                Finalizar
            </Button>;
    };


    const renderActivitiesList = (data: ActivitiesForToday.Data[], loading: LoadingOverview[]) => {
        return <List
            loading={loading.includes('activities')}
            style={{ marginBottom: 10 }}
            size='small'
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
                item.model === 'issue'
                    ? <Popover
                        content={item.lastest_follow_up?.description
                            ? <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastest_follow_up?.description }}></div>
                            : ''}
                        title={item.lastest_follow_up
                            ? `${item.lastest_follow_up?.user.name} em ${dayjs(item.lastest_follow_up?.created_at).format('DD/MM/YYYY HH:mm')}`
                            : 'Nenhuma atualização'}>
                        <List.Item actions={[makeActions(item)]}>
                            <List.Item.Meta
                                avatar={
                                    item.responsible
                                        ? <Avatar size={'large'} src={item.picture} style={{ backgroundColor: wl.colorPrimary }}>
                                            {getUserInitials(item.responsible)}
                                        </Avatar>
                                        : <Avatar size={'large'} icon={<SnippetsOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                                }
                                title={item.title}
                                description={
                                    <Fragment>
                                        <strong>{dayjs(item.deadline).format('DD/MM/YYYY')}</strong><br />
                                        {item.description}
                                    </Fragment>
                                }
                            />
                        </List.Item>
                    </Popover>

                    : <List.Item actions={[makeActions(item)]}>
                        <List.Item.Meta
                            avatar={
                                item.model === 'maintenance'
                                    ? <Avatar size={'large'} icon={<SettingOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                                    : item.responsible
                                        ? <Avatar size={'large'} src={item.picture} style={{ backgroundColor: wl.colorPrimary }}>
                                            {getUserInitials(item.responsible)}
                                        </Avatar>
                                        : <Avatar size={'large'} icon={<SnippetsOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                            }
                            title={item.title}
                            description={
                                <Fragment>
                                    <strong>{item.description}</strong><br />
                                    {dayjs(item.deadline).format('DD/MM/YYYY')}
                                </Fragment>
                            }
                        />
                    </List.Item>
            )}
        />;
    };

    const renderRecentActivitiesList = (data: RecentActivities.Data[], loading: LoadingOverview[]) => {
        return <List
            loading={loading.includes('recent_activities')}
            style={{ marginBottom: 10 }}
            size='small'
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
                item.model === 'issue'
                    ? <Popover
                        content={item.lastest_follow_up?.description
                            ? <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastest_follow_up?.description }}></div>
                            : ''}
                        title={item.lastest_follow_up
                            ? `${item.lastest_follow_up?.user.name} em ${dayjs(item.lastest_follow_up?.created_at).format('DD/MM/YYYY HH:mm')}`
                            : 'Nenhuma atualização'}>
                        <List.Item actions={[makeRecentActions(item)]}>
                            <List.Item.Meta
                                avatar={
                                    item.responsible
                                        ? <Avatar size={'large'} src={item.picture} style={{ backgroundColor: wl.colorPrimary }}>
                                            {getUserInitials(item.responsible)}
                                        </Avatar>
                                        : <Avatar size={'large'} icon={<SnippetsOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                                }
                                title={item.title}
                                description={
                                    <Fragment>
                                        <strong>{item.description}</strong><br />
                                        Última atualização {dayjs(item.updated_at).format('DD/MM/YYYY HH:mm')}
                                    </Fragment>
                                }
                            />
                        </List.Item>
                    </Popover>

                    : <List.Item actions={[makeRecentActions(item)]}>
                        <List.Item.Meta
                            avatar={
                                item.model === 'maintenance'
                                    ? <Avatar size={'large'} icon={<SettingOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                                    : item.responsible
                                        ? <Avatar size={'large'} src={item.picture} style={{ backgroundColor: wl.colorPrimary }}>
                                            {getUserInitials(item.responsible)}
                                        </Avatar>
                                        : <Avatar size={'large'} icon={<SnippetsOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                            }
                            title={item.title}
                            description={
                                <Fragment>
                                    <strong>{item.description}</strong><br />
                                    Última atualização {dayjs(item.updated_at).format('DD/MM/YYYY HH:mm')}
                                </Fragment>
                            }
                        />
                    </List.Item>
            )}
        />;
    };

    const issuesWithDeadline = (issues: GetUserIssues.Data[], loading: LoadingOverview[]) => (
        <List
            loading={loading.includes('user_issues')}
            size='small'
            itemLayout="horizontal"
            dataSource={issues}
            renderItem={(item) => (
                <Popover
                    content={item.lastest_follow_up?.description
                        ? <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastest_follow_up?.description }}></div>
                        : ''}
                    title={item.lastest_follow_up
                        ? `${item.lastest_follow_up?.user.name} em ${dayjs(item.lastest_follow_up?.created_at).format('DD/MM/YYYY HH:mm')}`
                        : 'Nenhuma atualização'}>
                    <List.Item actions={[
                        <Button href={makeUrlWithParameter(AuthRoutes.overviewIssue, 'detail', item.id.toString())} target='_blank'>
                            Detalhes
                        </Button>
                    ]}>
                        <List.Item.Meta
                            avatar={
                                item.is_overdue
                                    ? <Avatar size='large' icon={<ClockCircleOutlined />} style={{ backgroundColor: '#ff4d4f' }} />
                                    : <Avatar size='large' icon={<CloseCircleOutlined />} style={{ backgroundColor: '#d48806' }} />
                            }
                            title={item.title}
                            description={
                                <Fragment>
                                    <strong style={{ color: item.is_overdue ? '#ff4d4f' : '#d48806' }}>{item.deadline_description}</strong><br />
                                    {item.description}
                                </Fragment>
                            }
                        />
                    </List.Item>
                </Popover>
            )}
        />
    );

    const issuesFromResident = (issues: Issue.Model[], loading: LoadingOverview[]) => (
        <List
            loading={loading.includes('pendingIssues')}
            size='small'
            itemLayout="horizontal"
            dataSource={issues}
            renderItem={(item) => (
                <List.Item actions={[
                    <Button href={makeUrlWithParameter(AuthRoutes.overviewIssue, 'detail', item.id.toString())} target='_blank'>
                        Detalhes
                    </Button>
                ]}>
                    <List.Item.Meta
                        avatar={<Avatar size='large' icon={<EditOutlined />} style={{ backgroundColor: '#d48806' }} />}
                        title={item.client.name}
                        description={
                            <Fragment>
                                <strong style={{ color: wl.colorPrimary }}>Aberto  por {item.resident
                                    ? `${item.resident.name} (${item.resident.apartment.tower.name} - ${item.resident.apartment.name})`
                                    : item.subject}</strong><br />
                                {item.description}
                            </Fragment>
                        }
                    />
                </List.Item>
            )}
        />
    );

    const fetchInvoices = async () => {
        const response = await listInvoices(clientManager.cobre_facil_id);

        if (!response.success)
            return;

        setInvoices(response.invoices);
    };

    const makeLabelTimeAvg = (timeAvg: number) => {
        const dias = Math.floor(timeAvg);
        const horasDecimais = (timeAvg - dias) * 24;
        const horas = Math.floor(horasDecimais);
        const minutos = Math.round((horasDecimais - horas) * 60);

        let resultado = '';

        if (dias > 0) {
            resultado += `${dias} dia${dias > 1 ? 's' : ''}`;
        }

        if (horas > 0) {
            if (resultado) resultado += ' e ';
            resultado += `${horas} hora${horas > 1 ? 's' : ''}`;
        }

        if (minutos > 0 && dias === 0) { // Só mostra minutos se for menos de um dia
            if (resultado) resultado += ' e ';
            resultado += `${minutos} min`;
        }

        return resultado || '0 horas';
    };

    useEffect(() => {
        if (clientManager.cobre_facil_id)
            fetchInvoices();
    }, []);

    return (
        <OverviewContextProvider>
            {({ loadings, generalStatistics, activitiesForToday, userIssues, recentActivities, pendingIssues, documents, isDocumentsModalVisible, setIsDocumentsModalVisible }) => (
                <Content>
                    <Row style={{ alignItems: 'end', justifyContent: 'space-between' }}>
                        <Col span={18}>
                            <Typography.Title level={3} style={{ marginBottom: 0 }}>
                                Bem vindo, {userName}!
                            </Typography.Title>

                            <Typography.Text type="secondary">
                                Somos a <b style={style}>evolução</b> da gestão condominial
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Button type="link" onClick={() => setOpen(true)}>Últimas atualizações <HistoryOutlined /></Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col xs={24} sm={24} md={5} lg={5}>
                                    <Indicator
                                        loading={loadings.includes('indicators')}
                                        iconBackgroundColor='#ff4d4f'
                                        icon={<ClockCircleOutlined />}
                                        label='Chamados atrasados'
                                        value={generalStatistics.total_overdue_issues} />
                                </Col>
                                <Col xs={24} sm={24} md={5} lg={5}>
                                    <Indicator
                                        loading={loadings.includes('indicators')}
                                        iconBackgroundColor='#d48806'
                                        icon={<CloseCircleOutlined />}
                                        label='Chamados pendentes'
                                        value={generalStatistics.total_pending_issues} />
                                </Col>
                                <Col xs={24} sm={24} md={7} lg={7}>
                                    <Indicator
                                        loading={loadings.includes('indicators')}
                                        label="Tempo médio de resolução dos chamados"
                                        value={makeLabelTimeAvg(generalStatistics.time_avg)}
                                        icon={<ClockCircleOutlined />}
                                        iconBackgroundColor='#0B1A62'
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={7} lg={7}>
                                    <Indicator
                                        loading={loadings.includes('indicators')}
                                        label="Vencendo nos próximos 60 dias"
                                        value={`${documents.length} documento(s)`}
                                        icon={<FileOutlined />}
                                        iconBackgroundColor='#d48806'
                                        onClick={() => setIsDocumentsModalVisible(true)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={10} lg={10}>
                                    <Collapse
                                        defaultActiveKey={1}
                                        accordion
                                        size="large"
                                        items={[
                                            { key: '1', label: 'Meus chamados atrasados', children: issuesWithDeadline(userIssues.late, loadings), extra: <Badge count={userIssues.late.length} showZero /> },
                                            { key: '2', label: 'Meus chamados que vencem hoje', children: issuesWithDeadline(userIssues.dueToday, loadings), extra: <Badge color='#d48806' count={userIssues.dueToday.length} showZero /> },
                                            { key: '3', label: 'Meus chamados que vencem nos próximos dias', children: issuesWithDeadline(userIssues.dueThisWeek, loadings), extra: <Badge color='#d48806' count={userIssues.dueThisWeek.length} showZero /> },
                                            { key: '4', label: 'Chamados abertos por condôminos', children: issuesFromResident(pendingIssues.filter(i => i.resident || i.external_email), loadings), extra: <Badge color='#d48806' count={pendingIssues.filter(i => i.resident || i.external_email).length} showZero /> },
                                        ]}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={14}>
                                    <Collapse
                                        defaultActiveKey={1}
                                        accordion
                                        size="large"
                                        items={[
                                            { key: '1', label: 'Chamados da equipe atrasados', children: renderActivitiesList(activitiesForToday.issuesLate, loadings), extra: <Badge count={activitiesForToday.issuesLate.length} showZero /> },
                                            { key: '2', label: 'Chamados da equipe que vencem hoje', children: renderActivitiesList(activitiesForToday.issuesDueToday, loadings), extra: <Badge color='#d48806' count={activitiesForToday.issuesDueToday.length} showZero /> },
                                            { key: '3', label: 'Chamados da equipe que vencem nos próximos dias', children: renderActivitiesList(activitiesForToday.issuesDueThisWeek, loadings), extra: <Badge color='#d48806' count={activitiesForToday.issuesDueThisWeek.length} showZero /> },
                                            { key: '4', label: 'Manutenções atrasadas', children: renderActivitiesList(activitiesForToday.maintenancesLate, loadings), extra: <Badge count={activitiesForToday.maintenancesLate.length} showZero /> },
                                            { key: '5', label: 'Manutenções que vencem hoje', children: renderActivitiesList(activitiesForToday.maintenancesDueToday, loadings), extra: <Badge color='#d48806' count={activitiesForToday.maintenancesDueToday.length} showZero /> },
                                            { key: '6', label: 'Manutenções que vencem nos próximos dias', children: renderActivitiesList(activitiesForToday.maintenancesDueThisWeek, loadings), extra: <Badge color='#d48806' count={activitiesForToday.maintenancesDueThisWeek.length} showZero /> },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <PendingInvoicesModal invoices={invoices} />
                    <Drawer width={700} title="Atualizações recentes" onClose={() => setOpen(false)} open={open}>
                        {renderRecentActivitiesList(recentActivities, loadings)}
                    </Drawer>
                    <Show when={isDocumentsModalVisible}>
                        <ListDocumentModal />
                    </Show>
                </Content>
            )}
        </OverviewContextProvider>
    );
}
