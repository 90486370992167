import API from '../lib/API';
import {
    CreateConstruction,
    CreateConstructionFollowUp,
    CreateConstructionFollowUpFile,
    DeleteConstruction,
    DeleteConstructionFollowUp,
    FindConstruction,
    ListConstructions,
    UpdateConstruction,
    UpdateConstructionFollowUp,
} from './contracts/Construction.contract';

const route = '/construction';

export function listConstructions(client_id: Client.Model['id'] | null): Promise<ListConstructions.Response> {
    const params = new URLSearchParams();

    if (client_id)
        params.append('client_id', client_id.toString());

    return API.get(`${route}?${params.toString()}`);
}

export function createConstruction(body: CreateConstruction.Body): Promise<CreateConstruction.Response> {
    return API.post(route, body);
}

export function findConstruction(id: Construction.Model['id']): Promise<FindConstruction.Response> {
    return API.get(`${route}/${id}`);
}

export function updateConstruction(id: Construction.Model['id'], body: UpdateConstruction.Body): Promise<UpdateConstruction.Response> {
    return API.put(`${route}/${id}`, body);
}

export function deleteConstruction(id: Construction.Model['id']): Promise<DeleteConstruction.Response> {
    return API.delete(`${route}/${id}`);
}

export function createConstructionFollowUp(body: CreateConstructionFollowUp.Body): Promise<CreateConstructionFollowUp.Response> {
    return API.post(`${route}/follow_up`, body);
}

export function updateConstructionFollowUp(id: Construction.FollowUp.Model['id'], body: UpdateConstructionFollowUp.Body): Promise<UpdateConstructionFollowUp.Response> {
    return API.put(`${route}/follow_up/${id}?removeFiles=1`, body);
}

export function deleteConstructionFollowUp(id: Construction.Model['id']): Promise<DeleteConstructionFollowUp.Response> {
    return API.delete(`${route}/follow_up/${id}`);
}

export function createConstructionFollowUpFile(body: CreateConstructionFollowUpFile.Body): Promise<CreateConstructionFollowUpFile.Response> {
    return API.post(`${route}/follow_up/file`, body);
}
