import React, { useReducer, useState } from 'react';

import { Button, Col, Input, Row, Select } from 'antd';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { isFilledField, makeFilterOption } from 'lib/helpers/Form.helper';
import { useDocument } from 'lib/providers/DocumentContextProvider';
import { Show } from 'lib/Show';
import { makeClientOptions } from 'pages/issue/Filters';
import type { OptionalField } from 'types/packages/antd';

type Props = {
    /** @default false */
    isOverviewModule?: boolean
};

type Filter = {
    client: OptionalField<Document.Model['client_id']>,
    title: OptionalField<Document.Model['title']>,
    type: OptionalField<Document.Model['document_type_id']>,
    situation: OptionalField<Document.Model['situation']>,
}

const reducer = (state: Filter, newState: Partial<Filter>) => ({ ...state, ...newState });

const FILTER_DEFAULT_VALUE: Filter = {
    client: undefined,
    title: undefined,
    type: undefined,
    situation: undefined,
};

const filterOption = makeFilterOption();

export function DocumentsFilterBar({ isOverviewModule = false }: Props) {
    const [filter, setFilter] = useReducer(reducer, FILTER_DEFAULT_VALUE);

    const [isFiltering, setIsFiltering] = useState(false);

    const { 
        documents,
        setDocuments,
        fetchDocuments,
        types,
    } = useDocument();

    const handleClear = () => {
        setIsFiltering(false);
        setFilter(FILTER_DEFAULT_VALUE);
        fetchDocuments();
    };

    const handleFilter = () => {
        let filteredDocuments = documents;

        const { 
            client,
            title,
            type,
            situation,
        } = filter;

        if (isFilledField<Document.Model['client_id']>(client)) 
            filteredDocuments = filteredDocuments.filter(document => document.client_id === client);

        if (isFilledField<Document.Model['title']>(title))
            filteredDocuments = filteredDocuments.filter(document => document.title.toLowerCase().includes(title.toLowerCase()));

        if (isFilledField<Document.Model['document_type_id']>(type))
            filteredDocuments = filteredDocuments.filter(document => document.document_type_id === type);

        if (isFilledField<Document.Model['situation']>(situation))
            filteredDocuments = filteredDocuments.filter(document => document.situation === situation);

        setIsFiltering(true);
        setDocuments(filteredDocuments);
    };

    /** Disable the filter action when all filters are empty */
    const isActionButtonDisabled = Object.values(filter).every(value => value === undefined);
    
    const parsedTypes = types.map(({ id, name }) => ({ value: id, label: name }));
    
    const parsedSituations = [
        { value: 'invalid', label: 'Inválido' },
        { value: 'valid', label: 'Válido' },
        { value: 'overdue', label: 'Vencido' },
    ];

    return (
        <Row gutter={[8, 8]}>
            <Show when={isOverviewModule}>
                <Col md={6} xs={24}>
                    <Select
                        style={{ fontWeight: 'normal', width: '100%' }}
                        options={makeClientOptions()}
                        value={filter.client}
                        onChange={value => setFilter({ client: value })}
                        onClear={() => setFilter({ client: undefined })}
                        placeholder="Condomínio"
                        filterOption={filterOption}
                        disabled={isFiltering}
                        allowClear
                        showSearch
                    />
                </Col>
            </Show>

            <Col md={isOverviewModule ? 6 : 12} xs={24}>
                <Input
                    value={filter.title ?? undefined}
                    onChange={event => setFilter({ title: event.currentTarget.value })}
                    placeholder="Título"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={parsedTypes}
                    value={filter.type}
                    onChange={value => setFilter({ type: value })}
                    onClear={() => setFilter({ type: undefined })}
                    placeholder="Tipo"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={parsedSituations}
                    value={filter.situation}
                    onChange={value => setFilter({ situation: value })}
                    onClear={() => setFilter({ situation: undefined })}
                    placeholder="Situação"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={4} xs={24}>
                <Button
                    block
                    type={isFiltering ? 'dashed' : 'primary'}
                    onClick={isFiltering ? handleClear : handleFilter}
                    icon={isFiltering ? <ClearOutlined /> : <FilterOutlined />}
                    title={isActionButtonDisabled ? 'Por favor, selecione ao menos um filtro.' : undefined}
                    disabled={isActionButtonDisabled}
                >
                    {isFiltering ? 'Limpar' : 'Filtrar'}
                </Button>
            </Col>
        </Row>
    );
}
