import API from '../lib/API';
import { GetClientManagerQRCode, GetInstanceStatus, ListGroups } from './contracts/ZApi.contract';

const route = '/zapi';

export function getClientManagerQRCode(): Promise<GetClientManagerQRCode.Response> {
    return API.get(`${route}/client_manager_qrcode`);
};

export function getInstanceStatus(): Promise<GetInstanceStatus.Response> {
    return API.get(`${route}/instance_status`);
};

export function listGroups(): Promise<ListGroups.Response> {
    return API.get(`${route}/groups`);
};