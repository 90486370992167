import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Col,
    ColProps,
    Form,
    Input,
    Modal,
    notification,
    Radio,
    Row,
    Select,
    Upload,
    UploadFile
} from 'antd';
import { FormItemProps } from 'antd/lib/form';

import API from 'lib/API';
import { ClientsField } from 'lib/ClientsField';
import { Authorization, PROFILE } from 'lib/helpers/Authorization.helper';
import { sleep } from 'lib/Sleep';
import WhatsAppNumberField from 'lib/WhatsAppNumberField';
import { listAccountClients } from 'services/Account.service';

import Authentication from '../../lib/Authentication';
import { createUser, updateUser } from '../../services/UserService';
import { ClientInterface } from '../_Common/_Interfaces/Client';

type Props = {
    isOpen: boolean,
    closeForm: Function,
    user: User.Model | undefined
};

export type Values = {
    clientsToSync: number[],
    upload?: [UploadFile],
    name: User.Model['name'],
    email: User.Model['email'],
    phone: User.Model['phone'],
    profile: User.Model['profile'],
    picture: string
    can_only_view_assigned_issues: boolean
};

const FormUser = (props: Props) => {
    const history = useHistory();
    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [clients, setClients] = useState<Array<{
        value: number,
        label: string
    }>>([]);
    const [clientsCurrent, setClientsCurrent] = useState<number[]>([]);

    const colSmallSizes: ColProps = {
        xs: 24,
        sm: 24,
        md: 24,
    };

    async function onFinish() {
        try {
            setSaving(true);

            const user = await form.validateFields();

            let picture = null;

            if (user.upload !== undefined) {
                const upload: UploadFile = form.getFieldValue('upload')[0];
                picture = upload.response ? upload.response : upload.url;
                delete user.upload;
            }

            const response = props.user?.id
                ? await updateUser(props.user?.id, { ...user, picture })
                : await createUser({ ...user, picture });

            notification.open(response);
            setSaving(false);
            props.closeForm();

            await sleep(1000);

            if (props.user?.id === Authentication.getUserId()) {
                notification.info({
                    message: 'Atenção',
                    description: 'Estamos redirecionando você para página de login... Para que seu usuário seja atualizado, você deve realizar novamente seu acesso.',
                    placement: 'top',
                    duration: 5
                });

                await sleep(3000);

                history.push('/login');
                Authentication.logout();
            }

        } catch (error) {
            setSaving(false);
        }
    };

    const fetchClient = async () => {
        setLoading(true);
        const response = await listAccountClients();

        const allowedClientsIds = Authentication.getClients().map(client => client.id);

        if (response.success) {

            const clientsOption = response.clients.map(({ id, name }) => ({
                value: id,
                label: name,
                disabled: !allowedClientsIds.includes(id)
            }));

            setClients(clientsOption);
        }

        const currentClients = props.user
            ? props.user.clients.map((c: ClientInterface) => c.id)
            : allowedClientsIds;

        setClientsCurrent(currentClients);
        setLoading(false);
    };

    useEffect(() => {
        if (props.user !== undefined) {
            const file: UploadFile = {
                uid: props.user.id.toString(),
                name: '',
                url: props.user.picture,
                status: 'done',
            };
            form.setFieldsValue({
                clientsToSync: clientsCurrent,
                upload: [file]
            }
            );
        }

        fetchClient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user]);

    const normFile: FormItemProps['getValueFromEvent'] = (event) => {
        if (Array.isArray(event))
            return event;

        return event?.fileList;
    };

    return (
        <Modal
            width={750}
            centered
            title={props.user?.id ? 'Editar usuário' : 'Novo usuário'}
            destroyOnClose={true}
            open={props.isOpen}
            confirmLoading={isSaving}
            onOk={onFinish}
            onCancel={() => props.closeForm()}
            afterClose={() => form.resetFields()}
            okText="Salvar"
        >
            <Form form={form} layout="vertical" initialValues={props.user} onFinish={onFinish}>
                <Form.Item
                    name="upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}

                >
                    <Upload
                        listType="picture-card"
                        action={`${API.api_url}/file/upload`}
                        maxCount={1}>
                        Foto
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: 'Por favor, insira um nome.' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Por favor, insira um e-mail.' }]}
                >
                    <Input />
                </Form.Item>

                <WhatsAppNumberField name='phone' />

                <Form.Item
                    name="profile"
                    label="Perfil"
                    rules={[{ required: true, message: 'Por favor, selecione um perfil.' }]}
                >
                    <Select>
                        {Object.values(PROFILE).map(profile => (
                            <Select.Option value={profile} key={profile}>
                                {Authorization.translateProfile(profile)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <ClientsField<Values>
                    name="clientsToSync"
                    label="Condomínios que o usuário deve ter acesso"
                    tooltip="Os condomínios listados são administrados por você. Caso queira, poderá liberar acesso a todos eles para o usuário."
                    rules={[{ required: true, message: 'Por favor, selecione ao menos um condomínio.' }]}
                    disabled={isLoading}
                    loading={isLoading}
                    options={clients}
                    form={form}
                />

                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="can_only_view_assigned_issues"
                            label="Deve visualizar apenas chamados pelo qual é responsável?"
                            rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                        >
                            <Radio.Group>
                                <Radio value={false}>Não</Radio>
                                <Radio value={true}>Sim</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    );

};

export default FormUser;