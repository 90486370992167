import React from 'react';

import { App, Button, Popconfirm, Space, Switch, TableColumnsType } from 'antd';

import { DeleteOutlined, SecurityScanOutlined } from '@ant-design/icons';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { translateAction, translateCondition, translateEvent, translateModule } from 'lib/helpers/Trigger.helper';
import { useWhatsApp } from 'lib/providers/WhatsAppContextProvider';
import { deleteTrigger, disableTrigger, enableTrigger } from 'services/Trigger.service';

type ActionProps = { trigger: Trigger.Model };

const ActionsCell = ({ trigger }: ActionProps) => {
    const [isPopconfirmVisible, setIsPopconfirmVisible] = React.useState(false);

    const { isTriggersLoading, fetchTriggers, setIsTriggerLoading, setIsTriggerLogModalVisible, setTriggerId } = useWhatsApp();
    const app = App.useApp();

    const handleEnable = async () => {
        setIsTriggerLoading(true);
        const response = await enableTrigger(trigger.id);

        if (!response.success)
            return handleServiceError(app, response);

        app.notification.success({ message: 'Gatilho ativado com sucesso!' });
        fetchTriggers();
    };

    const handleDisable = async () => {
        setIsTriggerLoading(true);
        const response = await disableTrigger(trigger.id);

        if (!response.success)
            return handleServiceError(app, response);

        app.notification.success({ message: 'Gatilho desativado com sucesso!' });
        fetchTriggers();
    };

    const handleDelete = async () => {
        setIsTriggerLoading(true);
        const response = await deleteTrigger(trigger.id);

        if (!response.success)
            return handleServiceError(app, response);

        app.notification.success({ message: 'Gatilho removido com sucesso!' });
        setIsPopconfirmVisible(false);
        fetchTriggers();
    };

    const handleLogs = () => {
        setTriggerId(trigger.id);
        setIsTriggerLogModalVisible(true);
    };

    return <Space size="middle">
        <Switch checked={trigger.active} onClick={() => trigger.active ? handleDisable() : handleEnable()} />
        <Button
            type="text"
            icon={<SecurityScanOutlined />}
            title="Logs"
            onClick={handleLogs}
        />

        <Popconfirm
            title="Excluir documento"
            description="Tem certeza que deseja excluir?"
            open={isPopconfirmVisible}
            placement="left"
            cancelText="Não"
            okText="Sim"
            okType="danger"
            okButtonProps={{ loading: isTriggersLoading }}
            onConfirm={handleDelete}
            onCancel={() => setIsPopconfirmVisible(false)}
        >
            <Button
                type="text"
                icon={<DeleteOutlined />}
                title="Excluir"
                onClick={() => setIsPopconfirmVisible(true)}
            />
        </Popconfirm>
    </Space>;
};

export const TRIGGER_COLUMNS: TableColumnsType<Trigger.Model> = [
    {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (name) => <Space>{name}</Space>,
    },
    {
        title: 'Descrição',
        dataIndex: 'description',
        key: 'description',
        render: (description) => <Space>{description ?? '-'}</Space>,
    },
    {
        title: 'Evento',
        key: 'event',
        render: (_, trigger) => <Space>{translateModule(trigger.module)} - {translateEvent(trigger.event)}</Space>,
    },
    {
        title: 'Condições',
        dataIndex: 'conditions',
        key: 'conditions',
        render: (conditions) => <ul>{conditions.map((condition: Trigger.Condition) => (
            <li>{translateCondition(condition.field)}</li>
        ))}</ul>,
    },
    {
        title: 'Enviar mensagem para',
        dataIndex: 'actions',
        key: 'actions',
        render: (actions) => <ul>{actions.map((action: Trigger.Action) => (
            <li>{translateAction(action.action)}</li>
        ))}</ul>,
    },
    {
        title: 'Ações',
        key: 'actions',
        align: 'center',
        render: (_, trigger) => <ActionsCell trigger={trigger} />,
    },
];