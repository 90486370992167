import { getAllowedClients } from 'services/UserService';

import { Authorization } from './helpers/Authorization.helper';

enum LocalStorageEnum {
    CLIENTS = 'clients',
    CURRENT_CLIENT_ID = 'currentClientId',
    USER_JWT = 'userJWT',
    USER_ID = 'userId',
    USER_NAME = 'userName',
    USER_EMAIL = 'userEmail',
    USER_PROFILE = 'user_profile',
    USER_PICTURE = 'user_picture',
    USER_NEED_CHANGE_PASWORD = 'need_change_password',
    CLIENT_MANAGER = 'client_manager'
};

class Authentication {
    getUser() {
        if (!this.isAuthenticated())
            return {};

        return {
            jwt: localStorage.userJWT,
            personal: {
                id: localStorage.id,
                name: localStorage.userName,
                email: localStorage.userEmail,
                picture: localStorage.user_picture
            }
        };
    }

    /** Retrieve JWT from local storage */
    getToken(): Authentication.JWT {
        const token = localStorage.getItem(LocalStorageEnum.USER_JWT);

        if (token === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.USER_JWT}" key does not exist in local storage.`);

        return token;
    }

    /** Set new JWT token into local storage */
    setToken(token: Authentication.JWT) {
        localStorage.setItem(LocalStorageEnum.USER_JWT, token);
    }

    /** Set new id into local storage */
    setUserId(id: User.Model['id']) {
        localStorage.setItem(LocalStorageEnum.USER_ID, id.toString());
    }

    /** Retrieve id info from local storage */
    getUserId(): User.Model['id'] {
        const id = localStorage.getItem(LocalStorageEnum.USER_ID);

        if (id === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.USER_ID}" key does not exist in local storage.`);

        return parseInt(id, 10);
    }

    setUserEmail(email: User.Model['email']) {
        localStorage.setItem(LocalStorageEnum.USER_EMAIL, email);
    };

    getUserEmail(): User.Model['email'] {
        const email = localStorage.getItem(LocalStorageEnum.USER_EMAIL);

        if (email === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.USER_EMAIL}" key does not exist in local storage.`);

        return email;
    };

    setUserProfile(profile: User.Model['profile']) {
        localStorage.setItem(LocalStorageEnum.USER_PROFILE, profile);
    };

    getUserProfile(): User.Model['profile'] {
        const profile = localStorage.getItem(LocalStorageEnum.USER_PROFILE);

        if (profile === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.USER_PROFILE}" key does not exist in local storage.`);

        if (!Authorization.isValidProfile(profile))
            throw new TypeError(`Invalid user profile was provided. The profile provided was: ${profile}`);

        return profile;
    };

    setUserName(name: User.Model['name']) {
        localStorage.setItem(LocalStorageEnum.USER_NAME, name);
    }

    getUserName(): User.Model['name'] {
        const name = localStorage.getItem(LocalStorageEnum.USER_NAME);

        if (name === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.USER_NAME}" key does not exist in local storage.`);

        return name;
    };

    setUserPicture(picture: User.Model['picture']) {
        localStorage.setItem(LocalStorageEnum.USER_PICTURE, picture);
    }

    getUserPicture(): User.Model['picture'] {
        const picture = localStorage.getItem(LocalStorageEnum.USER_PICTURE);

        if (picture === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.USER_PICTURE}" key does not exist in local storage.`);

        return picture;
    };

    setUserNeedChangePassword(needChangePassword: User.Model['need_change_password']) {
        localStorage.setItem(LocalStorageEnum.USER_NEED_CHANGE_PASWORD, needChangePassword.toString());
    }

    getUserNeedChangePassword(): User.Model['need_change_password'] {
        const needChangePassword = localStorage.getItem(LocalStorageEnum.USER_NEED_CHANGE_PASWORD);

        if (needChangePassword === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.USER_NEED_CHANGE_PASWORD}" key does not exist in local storage.`);

        /** @see https://webtips.dev/solutions/convert-string-to-boolean-in-typescript */
        return JSON.parse(needChangePassword);
    };

    setClients(clients: Client.Model[]) {
        localStorage.setItem(LocalStorageEnum.CLIENTS, JSON.stringify(clients));
    };

    getClients(): Client.Model[] {
        const clients = localStorage.getItem(LocalStorageEnum.CLIENTS);

        if (!clients)
            return [];

        return JSON.parse(clients);
    };

    setCurrentClientId(id: Client.Model['id']): void {
        localStorage.setItem(LocalStorageEnum.CURRENT_CLIENT_ID, id.toString());
    }

    getCurrentClientId(): Client.Model['id'] {
        const id = localStorage.getItem(LocalStorageEnum.CURRENT_CLIENT_ID);

        if (id === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.CURRENT_CLIENT_ID}" key does not exist in local storage.`);

        return parseInt(id, 10);
    }

    setClientManager(client_manager: ClientManager.Model): void {
        localStorage.setItem(LocalStorageEnum.CLIENT_MANAGER, JSON.stringify(client_manager));
    }

    getClientManager(): ClientManager.Model {
        const client_manager = localStorage.getItem(LocalStorageEnum.CLIENT_MANAGER);

        if (client_manager === null)
            throw new Error(`Something went wrong! The "${LocalStorageEnum.CLIENT_MANAGER}" key does not exist in local storage.`);

        return JSON.parse(client_manager);
    }

    async syncAllowedClientsUser() {
        const response = await getAllowedClients();

        if (!response.success)
            return;

        this.setClients(response.clients);
    }

    // /**
    //  * Set new user permissions into local storage
    //  * @return void
    //  */
    // setUserPermissions(newUserPermissions: [JSON]) {
    //     localStorage.userPermissions = newUserPermissions;
    // }

    // /**
    //  * Retrieve user permissions info from local storage
    //  * @return string
    //  */
    // getUserPermissions() {
    //     return JSON.parse(localStorage.userPermissions);
    // }

    /**
     * Clean authentication data inside Local Storage
     * @return void
     */
    logout() {
        localStorage.clear();
    }

    /**
     * Returns true in case the current session has not expired yet
     * @return boolean
     */
    isAuthenticated() {
        return (localStorage.userJWT !== '' && localStorage.userJWT !== undefined);
    }

    /** Store session data inside Local Storage */
    login(jwt: Authentication.JWT, { id, name, email, profile, picture, need_change_password, clients, client_manager }: User.Model) {
        this.setToken(jwt);

        this.setUserId(id);
        this.setUserName(name);
        this.setUserEmail(email);
        this.setUserProfile(profile);
        this.setUserNeedChangePassword(need_change_password);
        this.setUserPicture(picture);

        this.setClients(clients);
        this.setCurrentClientId(clients[0].id);
        this.setClientManager(client_manager);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Authentication();