import { createElement } from 'react';

import { TagProps } from 'antd';

import { BulbOutlined, CheckCircleOutlined, CloseCircleOutlined, HourglassOutlined, PauseCircleOutlined } from '@ant-design/icons';

type DerivedStatusTagProps = Pick<TagProps, 'color' | 'icon' | 'children'>;

export function getConstructionDerivedStatus(status: Construction.Model['status']): Construction.Status {

    if (status === 'planning')
        return 'planning';

    if (status === 'finished')
        return 'finished';

    if (status === 'paralyzed')
        return 'paralyzed';

    if (status === 'late')
        return 'late';

    return 'in_progress';
}

export function getConstructionDerivedStatusTagProps(derivedStatus: Construction.Status): DerivedStatusTagProps {
    switch (derivedStatus) {
    case 'planning':
        return { color: 'geekblue', icon: createElement(BulbOutlined), children: 'Em planejamento' };
    case 'finished':
        return { color: 'success', icon: createElement(CheckCircleOutlined), children: 'Finalizada' };
    case 'paralyzed':
        return { color: 'gold', icon: createElement(PauseCircleOutlined), children: 'Paralisada' };
    case 'late':
        return { color: 'error', icon: createElement(CloseCircleOutlined), children: 'Atrasada' };
    default:
        return { color: 'processing', icon: createElement(HourglassOutlined), children: 'Em andamento' };
    }
}