import React from 'react';

import { Form, Input } from 'antd';

export default function WhatsAppNumberField({ name }: { name: string }) {
    return (
        <Form.Item
            label="Celular (WhatsApp)"
            name={name}
            rules={[
                { required: true, message: 'O telefone é obrigatório!' },
                {
                    validator: (_, value) => {
                        const phoneRegex = /^\d{1,4}\d{2}9\d{8}$/; // Código do país (1-4 dígitos) + DDD (2 dígitos) + Número (9XXXXXXXX)
                        if (!value || phoneRegex.test(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject('Número inválido! Formato esperado: CÓDIGO + DDD + NÚMERO (Ex: 5511987654321)');
                    },
                },
            ]}
            help={<span>O código do Brasil é <strong>55</strong>. Certifique-se de incluir o código do país no número.</span>}
        >
            <Input placeholder="Digite o telefone (Ex: 5511987654321)" maxLength={15} />
        </Form.Item>
    );
}