import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listTriggers } from 'services/Trigger.service';
import { getClientManagerQRCode, getInstanceStatus, listGroups } from 'services/ZApi.service';
import { WhatsApp } from 'types/WhatsApp';

const WhatsAppContext = createContext<Value | null>(null);

type Props = {
    children: (value: Value) => ReactNode
};

type Value = {
    isLoading: boolean,
    isTriggersLoading: boolean,
    setIsTriggerLoading: React.Dispatch<React.SetStateAction<boolean>>,
    connected: boolean,
    error: string | null,
    smartphoneConnected: boolean,
    qrcode: string | null,
    groups: WhatsApp.Group[],
    isNewTriggerModalVisible: boolean,
    setIsNewTriggerModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    isTriggerLogModalVisible: boolean,
    setIsTriggerLogModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    triggers: Trigger.Model[],
    fetchTriggers: () => void,
    trigger: Trigger.Model | null,
    triggerId: Trigger.Model['id'] | null,
    setTriggerId: React.Dispatch<React.SetStateAction<Trigger.Model['id'] | null>>
};

export function WhatsAppContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isTriggersLoading, setIsTriggerLoading] = useState(false);
    const [triggers, setTriggers] = useState<Value['triggers']>([]);
    const [groups, setGroups] = useState<Value['groups']>([]);
    const [error, setError] = useState<Value['error']>(null);
    const [qrcode, setQrcode] = useState<Value['qrcode']>(null);
    const [connected, setConnected] = useState<Value['connected']>(false);
    const [smartphoneConnected, setSmartphoneConnected] = useState<Value['smartphoneConnected']>(false);
    const [isNewTriggerModalVisible, setIsNewTriggerModalVisible] = useState<Value['isNewTriggerModalVisible']>(false);
    const [isTriggerLogModalVisible, setIsTriggerLogModalVisible] = useState<Value['isTriggerLogModalVisible']>(false);
    const [triggerId, setTriggerId] = useState<Value['triggerId']>(null);

    const app = App.useApp();

    const fetchInstanceStatus = useCallback(async () => {
        setIsLoading(true);

        const response = await getInstanceStatus();

        console.log(response);

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setError(response.data.error);
        setConnected(response.data.connected);
        setSmartphoneConnected(response.data.smartphoneConnected);
    }, [app]);

    const fetchClientManagerQRCode = useCallback(async () => {
        setIsLoading(true);

        const response = await getClientManagerQRCode();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setQrcode(response.qrcode);
    }, [app]);

    const fetchGroups = useCallback(async () => {
        setIsLoading(true);

        const response = await listGroups();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setGroups(response.groups);
    }, [app]);

    const fetchTriggers = useCallback(async () => {
        setIsTriggerLoading(true);

        const response = await listTriggers();

        if (!response.success)
            return handleServiceError(app, response);

        setTriggers(response.triggers);
        setIsTriggerLoading(false);
    }, [app]);

    useEffect(() => {
        fetchInstanceStatus();
        fetchClientManagerQRCode();
        fetchGroups();
        fetchTriggers();
    }, [fetchInstanceStatus, fetchClientManagerQRCode, fetchGroups, fetchTriggers]);

    const trigger = useMemo(() => {
        if (!triggerId)
            return null;

        const found = triggers.find(trigger => trigger.id === triggerId);

        if (!found)
            throw new Error(`Could not find a trigger with id ${triggerId}`);

        return found;

    }, [triggers, triggerId]);

    const value: Value = {
        isLoading,
        isTriggersLoading,
        setIsTriggerLoading,
        connected,
        error,
        smartphoneConnected,
        qrcode,
        groups,
        isNewTriggerModalVisible,
        setIsNewTriggerModalVisible,
        isTriggerLogModalVisible,
        setIsTriggerLogModalVisible,
        triggers,
        fetchTriggers: fetchTriggers,
        trigger,
        triggerId,
        setTriggerId
    };

    return (
        <WhatsAppContext.Provider value={value}>
            {children(value)}
        </WhatsAppContext.Provider>
    );
}

export function useWhatsApp() {
    const context = useContext(WhatsAppContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `ScheduleContextProvider`.');

    return context;
}