import React, { Fragment, useEffect, useState } from 'react';

import { App, Avatar, Breadcrumb, Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Space, Tag, Timeline, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';

import { DeleteOutlined, EditOutlined, FilePdfOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { FilesGalery } from 'components/_Common/FilesGalery';
import dayjs, { Dayjs } from 'dayjs';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { getConstructionDerivedStatusTagProps } from 'lib/helpers/Construction.helper';
import { useConstruction } from 'lib/providers/ConstructionContextProvider';
import { Show } from 'lib/Show';
import { deleteConstructionFollowUp, updateConstruction } from 'services/Construction.service';
import { generateIndividualReportConstruction } from 'services/Report.service';

import EditFollowUpModal from './EditFollowUpModal';
import FollowUpCreateModal from './FollowUpCreateModal';

type Values = {
    name: string,
    description: Construction.Model['description'],
    status: Construction.Status,
    start_date: Construction.Model['start_date'] | Dayjs,
    expected_end_date: Construction.Model['expected_end_date'] | Dayjs,
    end_date: Construction.Model['end_date'] | Dayjs,
    approved_budget: Construction.Model['approved_budget'],
    final_budget: Construction.Model['final_budget'],
    created_by_user_id: Construction.Model['created_by_user_id'],
    client_id: number,
};

export function DetailConstructionModal() {

    const [isProcessingReport, setIsProcessingReport] = useState(false);

    const {
        construction,
        setIsDetailModalVisible,
        setIsFollowUpCreateModal,
        isFollowUpCreateModal,
        setFollowUpId,
        setConstructionId,
        isEditFollowUpModal,
        setIsEditFollowUpModal,
        fetchConstructions
    } = useConstruction();

    if (!construction)
        throw new Error('Value of the `issue` property is unknown');

    const close = () => {
        setIsDetailModalVisible(false);
        setConstructionId(null);
    };

    const generateReport = async () => {
        setIsProcessingReport(true);

        const response = await generateIndividualReportConstruction(construction.id);

        setIsProcessingReport(false);

        if (!response.success) {
            notification.error({
                message: 'Erro ao gerar relatório',
                description: response.message,
            });
            return;
        }

        window.open(response.file_url, '_blank');
    };

    const handleEdit = async (id: Construction.FollowUp.Model['id']) => {
        const followUpExists = construction.follow_ups.some(fup => fup.id === id);
        if (!followUpExists) {
            notification.error({
                message: 'Acompanhamento não encontrado',
                description: `Não foi possível encontrar o acompanhamento com ID ${id}.`,
            });
            return;
        }

        setFollowUpId(id);
        setIsEditFollowUpModal(true);
    };

    const deleteFollowUp = async (id: number) => {
        const response = await deleteConstructionFollowUp(id);
        if (!response.success)
            return;

        await fetchConstructions();
        notification.success({
            message: 'Sucesso',
            description: 'Acompanhamento deletado com sucesso'
        });
    };


    const props = getConstructionDerivedStatusTagProps(construction.status);

    const title = (
        <Row justify="space-between" style={{ marginRight: 20 }}>
            <Breadcrumb
                style={{
                    fontSize: 18,
                    color: '#000'
                }}
                separator="|"
                items={[
                    {
                        title: construction.client.name,
                    },
                    {
                        title: `Obra #${construction.id}`,
                    },
                    {
                        title: construction.name,
                    },
                    {
                        title: <Tag {...props} style={{ marginLeft: '5px', fontSize: 16 }} />
                    },
                ]}
            />
            <Button type='primary' icon={<FilePdfOutlined />} onClick={generateReport} loading={isProcessingReport}>
                Gerar relatório
            </Button>
        </Row>
    );

    const FollowUpTimeline = () => {
        const sortedFollowUps = construction.follow_ups.sort((a, b) =>
            dayjs(b.creation_date).isBefore(dayjs(a.creation_date)) ? -1 : 1
        );

        return sortedFollowUps.map(fup => {
            const actions = [];

            if (Authorization.hasAccess(FEATURE['CONSTRUCTION::WRITE'])) {
                actions.push(
                    <Popconfirm
                        title={'Tem certeza que deseja remover esse acompanhamento?'}
                        placement="top"
                        onConfirm={() => deleteFollowUp(fup.id)}
                        cancelText={'Cancelar'}
                        okText={'Sim, tenho certeza'}
                        okType={'danger'}
                    >
                        <DeleteOutlined />
                    </Popconfirm>,
                    <EditOutlined onClick={() => handleEdit(fup.id)} />
                );
            }

            return (
                <Row>
                    <Col span={24}>
                        <Card actions={actions}>
                            <Meta
                                avatar={<Avatar src={fup.user.picture} icon={<UserOutlined />} size="large" />}
                                title={`${fup.user.name} em ${dayjs(fup.creation_date).format('DD/MM/YYYY')}`}
                                description={<Fragment>
                                    <Typography.Paragraph>
                                        <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: fup.description || '' }}></div>
                                    </Typography.Paragraph>
                                    <FilesGalery files={fup.files} />
                                </Fragment>}
                            />
                        </Card>
                    </Col>
                </Row>
            );
        });
    };

    const [form] = Form.useForm<Values>();
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const {
            name,
            description,
            client_id,
            status,
            start_date,
            expected_end_date,
            end_date,
            approved_budget,
            final_budget,
        } = construction;

        form.setFieldsValue({
            name,
            description,
            client_id,
            status,
            start_date: start_date ? dayjs(start_date) : null,
            expected_end_date: expected_end_date ? dayjs(expected_end_date) : null,
            end_date: end_date ? dayjs(end_date) : null,
            approved_budget,
            final_budget,
        });
    }, [construction, form]);

    const onFinish = async () => {

        setIsSaving(true);

        const {
            name,
            description,
            start_date,
            expected_end_date,
            end_date,
            approved_budget,
            final_budget,
            client_id, } = await form.validateFields();

        await updateConstruction(construction.id, {
            name,
            description,
            start_date: start_date?.toString(),
            expected_end_date: expected_end_date?.toString(),
            end_date: end_date?.toString(),
            approved_budget,
            final_budget,
            client_id,
        });
        
        setIsSaving(false);

        fetchConstructions();
    };

    return (
        <Modal
            width='70%'
            title={title}
            onCancel={close}
            footer={null}
            open
        >
            <Row>
                <Col span={8}>
                    <Card loading={isSaving}>
                        <Form
                            form={form}
                            name="editConstruction"
                            layout="vertical"
                            autoComplete="off"
                            disabled={!Authorization.hasAccess(FEATURE['CONSTRUCTION::WRITE'])}
                        >
                            <Form.Item
                                name="name"
                                label="Título"
                                rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                            >
                                <Input maxLength={500} onBlur={onFinish} />
                            </Form.Item>

                            <Form.Item
                                name="description"
                                label="Descrição"
                            >
                                <Input maxLength={500} onBlur={onFinish} />
                            </Form.Item>

                            <Form.Item
                                name="start_date"
                                label="Data inicial"
                            >
                                <DatePicker
                                    size='middle'
                                    style={{ width: '100%' }}
                                    format={'DD/MM/YYYY'}
                                    onChange={onFinish}
                                />
                            </Form.Item>

                            <Form.Item
                                name="expected_end_date"
                                label="Data esperada"
                            >
                                <DatePicker
                                    size='middle'
                                    style={{ width: '100%' }}
                                    format={'DD/MM/YYYY'}
                                    onChange={onFinish}
                                />
                            </Form.Item>

                            <Form.Item
                                name="end_date"
                                label="Data final"
                            >
                                <DatePicker
                                    size='middle'
                                    style={{ width: '100%' }}
                                    format={'DD/MM/YYYY'}
                                    onChange={onFinish}
                                />
                            </Form.Item>

                            <Form.Item
                                name="approved_budget"
                                label="Orçamento aprovado"
                            >
                                <InputNumber
                                    addonBefore="R$"
                                    decimalSeparator=","
                                    min={1}
                                    precision={2}
                                    style={{ width: '100%' }}
                                    onBlur={onFinish}
                                />
                            </Form.Item>

                            <Form.Item
                                name="final_budget"
                                label="Orçamento final"
                            >
                                <InputNumber
                                    addonBefore="R$"
                                    decimalSeparator=","
                                    min={1}
                                    precision={2}
                                    style={{ width: '100%' }}
                                    onBlur={onFinish}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={16}>
                    <Card style={{ height: '63vh', overflow: 'auto' }}>
                        <Show when={Authorization.hasAccess(FEATURE['CONSTRUCTION::WRITE'])}>
                            <Row>
                                <Col span={24}>
                                    <Button
                                        type='dashed'
                                        size='large'
                                        onClick={() => setIsFollowUpCreateModal(true)}
                                        icon={<PlusOutlined />}
                                        block
                                    >
                                        Novo acompanhamento
                                    </Button>
                                </Col>
                            </Row>
                        </Show>
                        {FollowUpTimeline()}
                    </Card>
                </Col>
            </Row>
            <Show when={isFollowUpCreateModal}>
                <FollowUpCreateModal />
            </Show>
            <Show when={isEditFollowUpModal}>
                <EditFollowUpModal />
            </Show>
        </Modal>
    );
}
