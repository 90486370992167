import React, { useState } from 'react';

import {
    App,
    Form,
    Input,
    Modal,
    Radio,
    UploadFile
} from 'antd';

import { useIssue } from 'lib/providers/IssueContextProvider';
import { UploadField } from 'lib/UploadField';
import { FinishTask } from 'services/contracts/Issue.contract';
import { finishTask, uploadTaskFile } from 'services/Issue.service';

type Values = {
    justification: string,
    files: UploadFile[] | undefined,
    approvedItems: FinishTask.TaskType,
};

export default function FinishCheckboxModal() {
    const {
        setFinishCheckboxModal,
        task,
        fetchIssues
    } = useIssue();

    const [form] = Form.useForm<Values>();
    const [isSending, setIsSending] = useState(false);



    const optionsApprovedItems = [{ label: 'Conforme', value: 'approved' }, { label: 'Não conforme', value: 'disapproved' }];

    const app = App.useApp();

    const close = () => {
        setFinishCheckboxModal(false);
    };

    const onFinish = async (values: Values) => {
        const disapproved = values.approvedItems === FinishTask.TaskType.disapproved;

        if (disapproved && values.justification === undefined) {
            return app.notification.error({ message: 'Atenção', description: 'Necessário adicionar uma justificativa para finalizar está tarefa!' });
        }

        if (disapproved && values.files === undefined) {
            return app.notification.error({ message: 'Atenção', description: 'Necessário adicionar ao menos um arquivo para finalizar está tarefa!' });
        }

        if (!task) {
            throw new Error('Task not found!');
        }

        if (values.files && values.files.some(file => file.status === 'uploading')) {
            return app.notification.error(
                {
                    message: 'Atenção',
                    description: 'Aguarde o término do upload dos arquivos!'
                }
            );
        }

        setIsSending(true);

        const body: FinishTask.Body = {
            id: task.id,
            type: values.approvedItems,
            justification: values.justification ?? ''
        };

        await finishTask(body);

        values.files?.forEach(file => uploadTaskFile({
            filename: file.name,
            url: file.response ?? file.url,
            issue_checklist_task_id: task.id,
        }));

        close();
        setIsSending(false);

        fetchIssues();
    };

    return (
        <Modal
            centered
            title={`Finalizar tarefa - ${task?.description} `}
            destroyOnClose={true}
            onCancel={close}
            confirmLoading={isSending}
            okText="Salvar"
            open={true}
            onOk={form.submit}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>

                <Form.Item<Values>
                    name="approvedItems"
                    label="A tarefa está conforme ou não conforme?"
                    initialValue={'approved'}
                    rules={[{ required: true, message: 'Por favor, defina se a tarefa está conforme ou não conforme.' }]}
                >
                    <Radio.Group>
                        {optionsApprovedItems.map(option => (
                            <Radio key={option.value} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>

                <Form.Item<Values>
                    name="justification"
                    label="Justificativa"
                >
                    <Input.TextArea rows={5} />
                </Form.Item>

                <UploadField
                    name="files"
                    type="picture"
                    multiple
                />

            </Form>
        </Modal >
    );
}
