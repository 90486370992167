import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import {
    App,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Typography,
    type UploadFile,
} from 'antd';

import { CheckboxImage } from 'components/_Common/CheckboxImage';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';
import { Show } from 'lib/Show';
import { UploadField } from 'lib/UploadField';
import { createRequestServiceBudget } from 'services/ServiceBudget.service';
import { listSuppliers } from 'services/Supplier.service';

export type Values = {
    title: ServiceBudget.Model['title'],
    description: ServiceBudget.Model['description'],
    service_budget_id: ServiceBudget.Model['id'],
    supplier_id: ServiceBudget.Model['id'][],
    files: boolean
};

type File = {
    filename: string,
    url: string,
}

export function RequestServiceBudgetModal() {
    const [isSending, setIsSending] = useState(false);
    const [filesIsVisible, setFilesIsVisible] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [suppliers, setSuppliers] = useState<Supplier.Model[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

    const handleSelectionChange = (selected: string[]) => {
        setSelectedFiles(selected);
    };

    const app = App.useApp();

    const {
        serviceBudget,
        setIsRequestModalVisible,
        fetchServiceBudgets,
    } = useServiceBudget();

    if (serviceBudget === null)
        throw new TypeError('Null value was provided to `notice` constant!');

    const close = () => setIsRequestModalVisible(false);

    const [form] = Form.useForm<Values>();

    useEffect(() => {
        const fetchData = async () => {
            setIsSending(true);

            const response = await listSuppliers();

            if (!response.success)
                return;
            
            // Ignore current suppliers in service budget
            const currentSupplierIds = serviceBudget.requests.map(item => item.supplier.id);
            const suppliers = response.suppliers.filter(item => !currentSupplierIds.includes(item.id));
            
            setSuppliers(suppliers);
            setIsSending(false);
        };

        fetchData();
    }, [form, serviceBudget.requests]);

    const onFinish = async () => {
        setIsSending(true);

        const allFilesSelected = files.filter(file => selectedFiles.includes(file.url));

        const newFiles = (form.getFieldValue('new_files') as UploadFile<any>[])?.map((file: UploadFile<any>) => ({
            url: file.response, 
            filename: file.name,
        })) || [];
          
        const values = await form.validateFields();

        const body = {
            serviceBudgetId: serviceBudget.id,
            title: values.title,
            description: values.description,
            suppliers: values.supplier_id,
            files: [...allFilesSelected, ...newFiles],
        };

        const response = await createRequestServiceBudget(body);

        if (!response.success) {
            setIsSending(false);
            return handleServiceError(app, response);
        }

        setIsSending(false);

        fetchServiceBudgets();

        close();
    };

    const initialValues: Values = {
        title: serviceBudget.title,
        description: serviceBudget.description,
        service_budget_id: 0,
        supplier_id: [],
        files: false,
    };

    useEffect(() => {
        const filesIssueMap = serviceBudget.issues.flatMap(issue =>
            issue.issue_file?.map(file => ({
                filename: file.filename,
                url: file.url,
            })) || []
        ).filter(file => file.filename && file.url);
        
        const filesMaintenanceMap = serviceBudget.maintenances.flatMap(maintenance =>
            maintenance.files?.map(file => ({
                filename: file.filename,
                url: file.url,
            })) || []
        ).filter(file => file.filename && file.url);
        
        const files: File[] = [...filesIssueMap, ...filesMaintenanceMap];
        
        setFiles(files);
    }, [serviceBudget.issues, serviceBudget.maintenances]);

    return (
        <Modal
            open
            centered
            width={1000}
            title="Solicitar orçamento"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="editServiceBudget"
                layout="vertical"
                autoComplete="off"
                initialValues={initialValues}
            >
                <Row>
                    <Typography.Text>Informamos que todos os dados listados abaixo serão incluídos no e-mail de solicitação de orçamento enviado aos fornecedores e usuários relacionados ao orçamento. Por favor, revise as informações para garantir que estão corretas e completas antes de proceder.</Typography.Text>

                    <Divider />

                    <Col xs={24} sm={24} md={12} lg={12}>

                        <Form.Item
                            name="title"
                            label="Assunto"
                            rules={[{ required: true, message: 'Por favor, digite algo.' }]}
                        >
                            <Input maxLength={40} />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Descrição do orçamento"
                            rules={[{ required: true, message: 'Por favor, digite algo.' }]}
                        >
                            <ReactQuill theme="snow" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="files"
                            label="Deseja anexar os arquivos dos chamados e manutenções vinculados?"
                            rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                        >
                            <Radio.Group onChange={(e) => setFilesIsVisible(e.target.value)}>
                                <Radio value={1}>Sim</Radio>
                                <Radio value={0}>Não</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Show when={filesIsVisible}>
                            <CheckboxImage files={files} onSelectionChange={handleSelectionChange} />
                        </Show>

                        <Form.Item
                            name="supplier_id"
                            label="Fornecedores"
                            rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                            >
                                {suppliers
                                    .filter(supplier => supplier.email !== null)
                                    .map(supplier => (
                                        <Select.Option key={supplier.id} value={supplier.id}>
                                            {`${supplier.name}`}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>

                        <UploadField name='new_files' type='picture' multiple />

                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
