import React from 'react';
import { Link } from 'react-router-dom';

import { Modal, TableColumnsType } from 'antd';

import List from 'components/_Common/List';
import dayjs from 'dayjs';
import { useOverview } from 'lib/providers/OverviewContextProvider';

const COLUMNS: TableColumnsType<Document.With<'client' | 'document_type' | 'files'>> = [
    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
        key: 'clientName'
    },

    {
        title: 'Título',
        dataIndex: 'title',
    },

    {
        title: 'Tipo',
        dataIndex: ['document_type', 'name'],
    },

    {
        title: 'Vigência',
        render: (_, record) => {
            if (!record.start_date && !record.end_date) {
                return 'N/A';
            }

            const days = dayjs(record.end_date).diff(dayjs(), 'days');

            if (days < 0)
                return `Vencido há ${days} dia(s)`;

            return `Vence em ${days} dia(s)`;
        }
    },

    {
        title: 'Arquivos',
        dataIndex: ['files'],
        key: 'files',
        render: (_, record) => <ul>{record.files.map(file =>
            <li>
                <a
                    key={file.id}
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {file.filename}
                </a>
            </li>
        )}
        </ul>
    },
];

export function ListDocumentModal() {
    const { documents, setIsDocumentsModalVisible } = useOverview();
    const close = () => setIsDocumentsModalVisible(false);

    return (
        <Modal
            title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 20 }}>
                <span>Contrato(s) e apólice(s) de seguro</span>
                <Link to='/overview/document'>Acessar o módulo de documentos</Link>
            </div>}
            footer={null}
            onCancel={close}
            width={1100}
            open
        >
            <List
                data={documents}
                columns={COLUMNS}
            />

        </Modal>
    );
}
