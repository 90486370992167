import API from '../lib/API';
import { GenerateConsolidatedReportIssuesAndMaintenances, GenerateDetailedReportIssues, GenerateIndividualReportConstruction } from './Report.contract';

const route = '/report';

export function generateConsolidatedReportIssuesAndMaintenances(filters: Report.Filter): Promise<GenerateConsolidatedReportIssuesAndMaintenances.Response> {
    return API.post(`${route}/consolidated`, filters);
};

export function generateDetailedReportIssues(filters: Report.Filter): Promise<GenerateDetailedReportIssues.Response> {
    return API.post(`${route}/issues/detailed`, filters);
};

export function generateDetailedReportMaintenances(filters: Report.Filter): Promise<GenerateDetailedReportIssues.Response> {
    return API.post(`${route}/maintenances/detailed`, filters);
};

export function generateDetailedReportConstructions(filters: Report.Filter): Promise<GenerateDetailedReportIssues.Response> {
    return API.post(`${route}/constructions/detailed`, filters);
};

export function generateIndividualReportConstruction(construction_id: number): Promise<GenerateIndividualReportConstruction.Response> {
    return API.post(`${route}/constructions/individual/${construction_id}`);
};