import React, { Fragment } from 'react';

import { Button, Card, Col, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { ServiceBudgetFilterBar } from 'components/Overview/ServiceBudgetFilterBar';
import ChangeStatusModal from 'components/ServiceBudget/ChangeStatusModal';
import { CreateServiceBudgetModal } from 'components/ServiceBudget/CreateServiceBudgetModal';
import { DetailServiceBudgetModal } from 'components/ServiceBudget/DetailServiceBudgetModal';
import { EditServiceBudgetModal } from 'components/ServiceBudget/EditServiceBudgetModal';
import HistoryModal from 'components/ServiceBudget/HistoryModal';
import { RequestServiceBudgetModal } from 'components/ServiceBudget/RequestServiceBudgetModal';
import { ServiceBudgetContextProvider } from 'lib/providers/ServiceBudgetContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';

export function ServiceBudget() {
    return (
        <ServiceBudgetContextProvider>
            {({
                serviceBudgets,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isEditModalVisible,
                isDetailModalVisible,
                isRequestModalVisible,
                isChangeStatusModalVisible,
                isHistoryModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Orçamentos
                            </Typography.Title>

                            <Button
                                type="primary"
                                onClick={() => setIsCreateModalVisible(true)}
                            >
                                Criar orçamento
                            </Button>
                        </Row>
                        <Row>
                            <Col style={{ paddingRight: 0 }} span={24}>
                                <Card>
                                    <ServiceBudgetFilterBar />
                                    <List
                                        columns={Columns}
                                        data={serviceBudgets}
                                        loading={isLoading}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                    <Show when={isCreateModalVisible}>
                        <CreateServiceBudgetModal />
                    </Show>

                    <Show when={isEditModalVisible}>
                        <EditServiceBudgetModal />
                    </Show>

                    <Show when={isDetailModalVisible}>
                        <DetailServiceBudgetModal />
                    </Show>

                    <Show when={isChangeStatusModalVisible}>
                        <ChangeStatusModal />
                    </Show>

                    <Show when={isHistoryModalVisible}>
                        <HistoryModal />
                    </Show>

                    <Show when={isRequestModalVisible}>
                        <RequestServiceBudgetModal />
                    </Show>
                </Fragment>
            )}
        </ServiceBudgetContextProvider>
    );
}
