import { SelectProps } from 'antd';

type FilterOption = NonNullable<SelectProps['filterOption']>;

export const makeFilterOption = (): FilterOption => (input, option) => {
    const label = option?.label ? option.label : option?.children ?? '';

    if (typeof label !== 'string')
        return false;

    const result = label.toLowerCase().includes(input.toLowerCase());

    return result;
};

export const isFilledField = <TValue>(value: unknown): value is TValue => {
    if (value === null)
        return false;

    if (value === undefined)
        return false;

    if (value === '')
        return false;

    if (Array.isArray(value) && value.length === 0)
        return false;

    return true;
};
