import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { App } from 'antd';

import dayjs from 'dayjs';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { ActivitiesForToday, GetStatistics, GetUserIssues, RecentActivities } from 'services/contracts/Overview.contract';
import { listPendingIssuesByClientManagar } from 'services/Issue.service';
import { listPendingMaintenancesByClientManagar } from 'services/Maintenance.service';
import { getActivitiesForToday, getDocuments, getRecentActivities, getStatistics, getUserIssues } from 'services/Overview.service';

export type LoadingOverview = 'pendingIssues' | 'pendingMaintenances' | 'indicators' | 'activities' | 'user_issues' | 'recent_activities';

type PendingIssue = Issue.With<
    | 'user'
    | 'user_who_resolved'
    | 'user_responsible'
    | 'supplier'
    | 'issueType'
    | 'client',
    Issue.PendingModel
>;

type PendingMaintenance = Maintenance.PendingModel & { client: Client.Model };

export type OverviewContextValue = {
    loadings: LoadingOverview[],

    fetchPendingIssues: () => Promise<void>,

    pendingIssues: PendingIssue[],
    pendingMaintenances: PendingMaintenance[],

    isDocumentsModalVisible: boolean,
    setIsDocumentsModalVisible: Dispatch<SetStateAction<OverviewContextValue['isDocumentsModalVisible']>>,

    isCreateIssueModalVisible: boolean,
    setIsCreateIssueModalVisible: Dispatch<SetStateAction<OverviewContextValue['isCreateIssueModalVisible']>>,

    isIssueDetailsModalVisible: boolean,
    setIsIssueDetailsModalVisible: Dispatch<SetStateAction<OverviewContextValue['isIssueDetailsModalVisible']>>,

    pendingIssueId: PendingIssue['id'] | null,
    setPendingIssueId: Dispatch<SetStateAction<OverviewContextValue['pendingIssueId']>>,

    pendingIssue: PendingIssue | null,

    generalStatistics: GetStatistics.Data,
    activitiesForToday: {
        issuesLate: ActivitiesForToday.Data[],
        issuesDueToday: ActivitiesForToday.Data[],
        issuesDueThisWeek: ActivitiesForToday.Data[],
        maintenancesLate: ActivitiesForToday.Data[],
        maintenancesDueToday: ActivitiesForToday.Data[],
        maintenancesDueThisWeek: ActivitiesForToday.Data[]
    },
    userIssues: {
        late: GetUserIssues.Data[],
        dueToday: GetUserIssues.Data[],
        dueThisWeek: GetUserIssues.Data[]
    },
    recentActivities: RecentActivities.Data[]
    documents: Document.Model[]
};

type Props = { children: (value: OverviewContextValue) => ReactNode };

const OverviewContext = createContext<OverviewContextValue | null>(null);

/**
 * @see https://www.youtube.com/watch?v=I7dwJxGuGYQ
 * @todo Abstract `loadings` state updates (Add/Remove) with `useReducer`
 */
export function OverviewContextProvider({ children }: Props) {

    const [loadings, setLoadings] = useState<OverviewContextValue['loadings']>(['indicators', 'pendingMaintenances', 'pendingIssues', 'activities', 'user_issues', 'recent_activities']);
    const [pendingIssues, setPendingIssues] = useState<OverviewContextValue['pendingIssues']>([]);
    const [pendingMaintenances, setPendingMaintenances] = useState<OverviewContextValue['pendingMaintenances']>([]);

    const [isDocumentsModalVisible, setIsDocumentsModalVisible] = useState(false);
    const [isCreateIssueModalVisible, setIsCreateIssueModalVisible] = useState(false);
    const [isIssueDetailsModalVisible, setIsIssueDetailsModalVisible] = useState(false);

    const [pendingIssueId, setPendingIssueId] = useState<OverviewContextValue['pendingIssueId']>(null);

    const [generalStatistics, setGeneralStatistics] = useState<GetStatistics.Data>({} as GetStatistics.Data);
    const [recentActivities, setRecentActivities] = useState<RecentActivities.Data[]>([]);
    const [activitiesForToday, setActivitiesForToday] = useState<{
        issuesLate: ActivitiesForToday.Data[],
        issuesDueToday: ActivitiesForToday.Data[],
        issuesDueThisWeek: ActivitiesForToday.Data[],
        maintenancesLate: ActivitiesForToday.Data[],
        maintenancesDueToday: ActivitiesForToday.Data[],
        maintenancesDueThisWeek: ActivitiesForToday.Data[]
    }>({
        issuesLate: [],
        issuesDueToday: [],
        issuesDueThisWeek: [],
        maintenancesLate: [],
        maintenancesDueToday: [],
        maintenancesDueThisWeek: []
    });
    const [userIssues, setUserIssues] = useState<{
        late: GetUserIssues.Data[],
        dueToday: GetUserIssues.Data[],
        dueThisWeek: GetUserIssues.Data[]
    }>({
        late: [],
        dueToday: [],
        dueThisWeek: []
    });
    const [documents, setDocuments] = useState<Document.Model[]>([]);

    const app = App.useApp();

    const fetchPendingIssues = async () => {
        setLoadings(prevState => [...prevState, 'pendingIssues']);

        const response = await listPendingIssuesByClientManagar();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'pendingIssues')]);

        if (!response.success)
            return handleServiceError(app, response);

        setPendingIssues(response.issues);
    };

    const memoizedFetchPendingIssues = useCallback(fetchPendingIssues, [app]);

    const fetchPendingMaintenances = async () => {
        setLoadings(prevState => [...prevState, 'pendingMaintenances']);

        const response = await listPendingMaintenancesByClientManagar();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'pendingMaintenances')]);

        if (!response.success)
            return handleServiceError(app, response);

        setPendingMaintenances(response.maintenances);
    };

    const memoizedFetchPendingMaintenances = useCallback(fetchPendingMaintenances, [app]);

    const fetchGeneralStatistics = async () => {
        setLoadings(prevState => [...prevState, 'indicators']);

        const response = await getStatistics();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'indicators')]);

        if (!response.success)
            return handleServiceError(app, response);

        setGeneralStatistics(response.data);
    };

    const memoizedFetchGeneralStatistics = useCallback(fetchGeneralStatistics, [app]);

    const fetchRecentActivities = async () => {
        setLoadings(prevState => [...prevState, 'recent_activities']);

        const response = await getRecentActivities();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'recent_activities')]);

        if (!response.success)
            return handleServiceError(app, response);

        setRecentActivities(response.recent_activities);
    };

    const memoizedFetchRecentActivities = useCallback(fetchRecentActivities, [app]);

    const fetchActivitiesForToday = async () => {
        setLoadings(prevState => [...prevState, 'activities']);

        const response = await getActivitiesForToday();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'activities')]);

        if (!response.success)
            return handleServiceError(app, response);

        const issuesLate = response.activities.filter(item => dayjs(item.deadline).startOf('day').isBefore(dayjs().startOf('day')) && item.model === 'issue');
        const issuesDueToday = response.activities.filter(item => dayjs(item.deadline).startOf('day').isSame(dayjs().startOf('day')) && item.model === 'issue');
        const issuesDueThisWeek = response.activities.filter(item => dayjs(item.deadline).startOf('day').isAfter(dayjs().startOf('day')) && item.model === 'issue');
        const maintenancesLate = response.activities.filter(item => dayjs(item.deadline).startOf('day').isBefore(dayjs().startOf('day')) && item.model === 'maintenance');
        const maintenancesDueToday = response.activities.filter(item => dayjs(item.deadline).startOf('day').isSame(dayjs().startOf('day')) && item.model === 'maintenance');
        const maintenancesDueThisWeek = response.activities.filter(item => dayjs(item.deadline).startOf('day').isAfter(dayjs().startOf('day')) && item.model === 'maintenance');

        setActivitiesForToday({
            issuesLate,
            issuesDueToday,
            issuesDueThisWeek,
            maintenancesLate,
            maintenancesDueToday,
            maintenancesDueThisWeek
        });
    };

    const memoizedFetchActivitiesForToday = useCallback(fetchActivitiesForToday, [app]);

    const fetchUserIssues = async () => {
        setLoadings(prevState => [...prevState, 'user_issues']);

        const response = await getUserIssues();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'user_issues')]);

        if (!response.success)
            return handleServiceError(app, response);

        const late = response.issues.filter(item => dayjs(item.deadline).startOf('date').isBefore(dayjs().startOf('date')));
        const dueToday = response.issues.filter(item => dayjs(item.deadline).startOf('date').isSame(dayjs().startOf('date')));
        const dueThisWeek = response.issues.filter(item => dayjs(item.deadline).startOf('date').isAfter(dayjs().startOf('date')));

        setUserIssues({
            late,
            dueToday,
            dueThisWeek
        });
    };

    const memoizedFetchUserIssues = useCallback(fetchUserIssues, [app]);

    const fetchDocuments = useCallback(async () => {
        const response = await getDocuments();
        if (!response.success)
            return handleServiceError(app, response);

        setDocuments(response.documents);
    }, [app]);

    useEffect(() => {
        memoizedFetchPendingIssues();
        memoizedFetchPendingMaintenances();
        memoizedFetchGeneralStatistics();
        memoizedFetchActivitiesForToday();
        memoizedFetchUserIssues();
        memoizedFetchRecentActivities();
        fetchDocuments();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const pendingIssue = pendingIssues.find(issue => issue.id === pendingIssueId) ?? null;

    const value: OverviewContextValue = {
        loadings,

        fetchPendingIssues: memoizedFetchPendingIssues,

        pendingIssues,
        pendingMaintenances,

        isDocumentsModalVisible,
        setIsDocumentsModalVisible,

        isCreateIssueModalVisible,
        setIsCreateIssueModalVisible,

        isIssueDetailsModalVisible,
        setIsIssueDetailsModalVisible,

        pendingIssueId,
        setPendingIssueId,

        pendingIssue,

        generalStatistics,
        activitiesForToday,
        userIssues,
        recentActivities,
        documents
    };

    return (
        <OverviewContext.Provider value={value}>
            {children(value)}
        </OverviewContext.Provider>
    );
}

export function useOverview() {
    const context = useContext(OverviewContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `OverviewContextProvider`.');

    return context;
}
