import React, { ComponentProps, ReactNode, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Layout, Menu } from 'antd';

import { DollarOutlined, FieldTimeOutlined, FileSearchOutlined, FileTextOutlined, FileWordOutlined, FormatPainterOutlined, HomeOutlined, ScheduleOutlined, SettingOutlined, ShopOutlined, SnippetsOutlined, UserAddOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { AuthRoute, AuthRoutes } from 'components/_Common/_Routes/Routes';
import Authentication from 'lib/Authentication';
import { hasAuthRouteAccess } from 'lib/helpers/Router.helper';

import { SecondaryHeaderLogo } from './SecondaryHeaderLogo';

/** Based in `react-router-dom#useHistory#push` */
type Push = (location: string) => void;

type MenuItem = NonNullable<ComponentProps<typeof Menu>['items']>[number];

/** @factory */
const makeBasePath = (pathname: string): string => pathname
    .split('/')
    .slice(0, 3)
    .join('/');

/** @factory */
const makeMenuItem = (
    profile: User.Profile,
    route: AuthRoute,
    icon: ReactNode,
    label: string,
    push: Push,
): MenuItem | null => {
    if (!hasAuthRouteAccess(profile, route))
        return null;

    const item = {
        key: route,
        icon,
        label,
        onClick: () => push(route),
    };

    return item;
};

/** @factory */
const makeMenuItems = (push: Push): MenuItem[] => {
    const profile = Authentication.getUserProfile();

    const items: Array<MenuItem | null> = [
        makeMenuItem(
            profile,
            AuthRoutes.overview,
            <HomeOutlined />,
            'Dashboard',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewIssue,
            <SnippetsOutlined />,
            'Chamados',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewMaintenance,
            <SettingOutlined />,
            'Manutenções',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewConstruction,
            <FormatPainterOutlined />,
            'Obras',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewServiceBudget,
            <FileSearchOutlined />,
            'Orçamentos',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewSchedule,
            <ScheduleOutlined />,
            'Cronograma',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewPayment,
            <DollarOutlined />,
            'Pagamentos',
            push,
        ),


        makeMenuItem(
            profile,
            AuthRoutes.overviewDocument,
            <FileTextOutlined />,
            'Documentos',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewReport,
            <FileWordOutlined />,
            'Relatórios',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewSupplier,
            <ShopOutlined />,
            'Fornecedores',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewEmployee,
            <FieldTimeOutlined />,
            'Controle de férias',
            push,
        ),

        makeMenuItem(
            profile,
            AuthRoutes.whatsapp,
            <WhatsAppOutlined />,
            'WhatsApp',
            push
        ),

        makeMenuItem(
            profile,
            AuthRoutes.overviewUser,
            <UserAddOutlined />,
            'Usuários',
            push,
        ),
    ];

    const filteredItems = items.filter(item => item !== null);

    return filteredItems;
};

export function SecondarySider() {
    const [isCollapsed, setIsCollapsed] = useState(() => window.matchMedia('(max-width: 768px)').matches);

    const location = useLocation();
    const history = useHistory();

    const basePath = makeBasePath(location.pathname);
    const items = makeMenuItems(history.push);

    return (
        <Layout.Sider
            collapsible
            collapsed={isCollapsed}
            onCollapse={collapsed => setIsCollapsed(collapsed)}
            style={{ minHeight: '100vh' }}
        >
            <SecondaryHeaderLogo />

            <Menu
                style={{ marginTop: 30 }}
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[basePath]}
                items={items}
            />
        </Layout.Sider>
    );
}
