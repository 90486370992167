import React from 'react';

import { Collapse, List, Modal, Typography } from 'antd';

import { FilesGalery } from 'components/_Common/FilesGalery';
import dayjs from 'dayjs';
import { useServiceBudget } from 'lib/providers/ServiceBudgetContextProvider';
import { Show } from 'lib/Show';

export default function HistoryModal() {
    const {
        serviceBudget,
        setIsHistoryModalVisible,
    } = useServiceBudget();

    const close = () => {
        setIsHistoryModalVisible(false);
    };

    const statusTranslations: Record<string, string> = {
        pending: 'pendente',
        in_progress: 'em progresso',
        awaiting_approval: 'aguardando aprovação',
        awaiting_for_contract: 'aguardando contrato',
        awaiting_signature: 'aguardando assinatura',
        canceled: 'cancelado',
        done: 'finalizado',
    };

    const renderText = ({ user, current_status, new_status, created_at, comment }: ServiceBudget.Progress) => {
        const translatedCurrent = statusTranslations[current_status] || current_status;
        const translatedNew = statusTranslations[new_status] || new_status;
        const createdAt = dayjs(created_at).format('DD/MM/YYYY');
        const cleanedComment = comment?.replace(/<[^>]*>/g, '');

        const changeTranslation = translatedCurrent !== translatedNew
            ? `o status de <strong>${translatedCurrent}</strong> para <strong>${translatedNew}</strong>`
            : `para o status <strong>${translatedNew}</strong>`;
    
        return (
            <span dangerouslySetInnerHTML={{ __html: `
                    <strong>${user.name}</strong> alterou ${changeTranslation} em <strong>${createdAt}</strong>
                    <br />${cleanedComment ? `Descrição: "${cleanedComment}"` : ''}
                `}} 
            />
        );
    };

    const items = (log: ServiceBudget.Progress) => {
        return [
            {
                key: log.id,
                label: 'Arquivos',
                children: <FilesGalery files={log.files} />
            }
        ];
    };
    
    return (
        <Modal
            centered
            title="Histórico de mudanças de status"
            destroyOnClose={true}
            width={800}
            onCancel={close}
            open
            footer={null}
        >
            <List
                style={{
                    maxHeight: '30rem', 
                    overflowY: 'auto',  
                }}
                dataSource={serviceBudget?.progress}
                renderItem={(log) => {
                    return (
                        <>
                            <List.Item>
                                <Typography.Text>
                                    {renderText(log)}
                                </Typography.Text>
                            </List.Item>

                            <Show when={log.files.length > 0}>
                                <Collapse items={items(log)} style={{ maxHeight: 'auto', overflowY: 'auto', marginBottom: 10 }} />
                            </Show>
                        </>
                    );
                }}
            />
        </Modal >
    );
};